app.factory('finService', ['$q', '$rootScope', '$http', '$ocLazyLoad', 'broadcastService', '$filter', '$modal', 'controlService', function ($q, $rootScope, $http, $ocLazyLoad, broadcastService, $filter, $modal, controlService)
{
    let finServiceFactory = {};

    //record validation on server
    let _serverRecordValidation = function (Source, TransHdrKey, OUKey, Date, CCIDKey, InvNum, interOU, InterCompTransHdrKey, millTicket, InactiveGRNI, Status)
    {
        let defer = $q.defer();

        $http({
            url: $rootScope.financeWebApiUrl + 'FIN/GetRcdStateValidation?',
            method: "POST",
            params: {
                Source: Source,
                TransHdrKey: TransHdrKey,
                OUKey: OUKey,
                Date: Date,
                CCIDKey: typeof CCIDKey == "undefined" ? -1 : CCIDKey,
                InvNum: typeof InvNum == "undefined" ? "" : InvNum,
                interOU: typeof interOU == "undefined" ? "" : interOU,
                InterCompTransHdrKey: typeof InterCompTransHdrKey == "undefined" ? 0 : InterCompTransHdrKey,
                millTicket: typeof millTicket == "undefined" ? "" : millTicket,
                InactiveGRNI: typeof InactiveGRNI == "undefined" ? "" : InactiveGRNI,
                Status: typeof Status == "undefined" ? "" : Status
            }
        }).then(function (data)
        {
            defer.resolve(data.data);
        }, function (err)
        {
            defer.reject(err);
        });

        return defer.promise;
    };

    //record validation on value/amount validity
    let _currentRecordValidation = function (headerData, detData, allocData, finSettingData)
    {
        let breakLoop = false;
        let accuFuncAmt = 0;
        let accuOrigAmt = 0;
        let accuDocAmt = 0; // this only accumulate positive side - to verify against doc amount
        let currKeyFlag = -1;
        let enableOrigAmtCheck = true;
        let ErrorMsg = "";
        let isAOS = false;
        let strErr1 = "";
        let strErr2 = "";

        // detail validation
        angular.forEach(detData, function (data, index)
        {
            if (data.Ind == "AO")
            {
                isAOS = true;
            }
        });

        angular.forEach(detData, function (data, index)
        {
            if (!breakLoop)
            {
                if (data.AccKey > 0)
                {
                    if (data.ExRateFunc == 0)
                    {
                        breakLoop = true;
                        ErrorMsg = "Exchange rate cannot be 0. ";
                    }
                }

                if (headerData.Source === "SI" && typeof headerData.CDType === "string" && headerData.CDType === "ORD" && data.Ind == "O")
                {
                    //special handling, skip Acc/CCID checking and only check for sales item first
                    if (data.SalesItemKey <= 0)
                    {
                        breakLoop = true;
                        strErr1 += "Sales item, ";
                    }
                    else
                    {
                        if (data.AccKey <= 0)
                        {
                            breakLoop = true;
                            strErr1 += "Account, ";
                        }
                    }
                }
                else
                {
                    if (data.AccKey <= 0)
                    {
                        breakLoop = true;
                        strErr1 += "Account, ";
                    }

                    if (data.Ind != "RA" && data.Ind != "IA")
                    {
                        if (data.IsRequiredCCID == true)
                        {
                            if (data.CCIDKey == -1)
                            {
                                breakLoop = true;
                                strErr1 += "CC ID, ";
                            }
                        }
                    }
                }

                if (headerData.Source == "BP" || headerData.Source == "CP" || headerData.Source == "BR" || headerData.Source == "CR")
                {
                    if (data.Remarks == "")
                    {
                        breakLoop = true;
                        strErr1 += "Remarks, ";
                    }
                }
                else
                {
                    if (data.Ind != "V")
                    {
                        if (data.Remarks == "")
                        {
                            breakLoop = true;
                            strErr1 += "Remarks, ";
                        }
                    }
                }

                if (!data.Ind.startsWith("T") && data.Ind != "R1" && data.Ind != "R2" && data.Ind != "RA" && data.Ind != "VR" && data.Ind != "RL")
                {
                    if (isAOS == false)
                    {
                        if (headerData.Source == "PI")
                        {
                            if (data.RefTax != "N")
                            {
                                if (data.OrigTransAmt.NtoDP(2) == 0)
                                {
                                    breakLoop = true;
                                    strErr2 += "Amount cannot be zero. ";
                                }
                            }
                        }
                        else if (headerData.Source == "SI" && headerData.CDType === "ORD")
                        {
                            //skip checking for SI
                            if (typeof data.Discount === "undefined" || data.Discount == null || isNaN(+data.Discount) || !Number.isFinite(+data.Discount))
                            {
                                data.Discount = 0;
                            }

                            if (typeof data.DiscountTemp === "undefined" || data.DiscountTemp == null || isNaN(+data.DiscountTemp) || !Number.isFinite(+data.DiscountTemp))
                            {
                                data.DiscountTemp = 0;
                            }
                        }
                        else
                        {
                            if (data.OrigTransAmt.NtoDP(2) == 0)
                            {
                                breakLoop = true;
                                strErr2 += "Amount cannot be zero. ";
                            }
                        }
                    }
                }

                if (headerData.Source == "GJ")
                {
                    if (data.Ind.startsWith("T"))
                    {
                        if (data.TaxableTransAmt.NtoDP(2) == 0)
                        {
                            breakLoop = true;
                            strErr2 += "Taxable amount for tax account cannot be zero. ";
                        }
                    }
                }

                if (allocData !== undefined && allocData !== null)
                {
                    //validation amount not fully allocated out
                    if ((headerData.Source == "PI" || headerData.Source == "BP" || headerData.Source == "CP" || headerData.Source == "DN" || headerData.Source == "CN" || headerData.Source == "GJ"
                        || headerData.Source == "BR" || headerData.Source == "CR" || headerData.Source == "SI" || headerData.Source == "SBR" || headerData.Source == "SB") && allocData.length > 0)
                    {
                        let totalAllocatedAmt = 0, count = 0, Qty = 0, overQty = false, overAmt = false, wrongOUAllocAcc = false;
                        angular.forEach(allocData, function (data2, index2)
                        {
                            if ((data.TransDetKey != 0 && data2.TransDetKey == data.TransDetKey) || (data.rowid != null && data.rowid != "" && data2.rowid == data.rowid))
                            {
                                totalAllocatedAmt = totalAllocatedAmt.Nadd2(data2.OrigTransAmt);
                                Qty = Qty.Nadd(data2.Qty.NtoDP(3));
                                count++;
                            }

                            //only check for the line with OUAllocation
                            if (wrongOUAllocAcc == false && count > 0)
                            {
                                var rawData = finSettingData[0].finGLConfgOUAllocDetails;

                                rawData = rawData.filter(function (item)
                                {
                                    if (item.AccKey == data.AccKey)
                                    {
                                        return true;
                                    }
                                    else
                                    {
                                        return false;
                                    }
                                });

                                if (rawData.length == 0)
                                {
                                    breakLoop = true;
                                    wrongOUAllocAcc = true;
                                }
                            }
                        });

                        if (totalAllocatedAmt.NtoDP(2) != data.OrigTransAmt.NtoDP(2) && count > 0)
                        {
                            breakLoop = true;
                            overAmt = true;
                        }

                        if (data.Qty == undefined)
                        {
                            data.Qty = 0;
                        }

                        if (Qty.NtoDP(3) != data.Qty.NtoDP(3) && count > 0)
                        {
                            breakLoop = true;
                            overQty = true;
                        }

                        if (overAmt == true && overQty == true)
                        {
                            strErr2 += "Quantity and Amount cannot be fully allocated or over allocated. ";
                        }
                        else if (overAmt == true && overQty == false)
                        {
                            strErr2 += "Amount cannot be fully allocated or over allocated. ";
                        }
                        else if (overAmt == false && overQty == true)
                        {
                            strErr2 += "Quantity cannot be fully allocated or over allocated. ";
                        }
                        else if (wrongOUAllocAcc)
                        {
                            strErr2 += "The account for OU Allocation was not set in Account Setting.";
                        }
                    }
                }

                if (breakLoop)
                {
                    if (strErr1.length > 0)
                        strErr1 = strErr1.substring(0, strErr1.length - 2) + " cannot be empty. ";
                    ErrorMsg = strErr1 + strErr2;
                }

                //////////////////////////////////////////////////////////////////////////////////////////////////////////
                // this is to check for OrigTransAmt to be tally, logic to detect current row currency match with previous
                // we will exclude rounding and tax line
                /////////////////////////////////////////////////////////////////////////////////////////////////////////
                //&& data.Ind != "RA" - Remove checking after remove VR line to make it balance
                if (data.Ind != "R1" && data.Ind != "R2" && data.Ind != "VR")
                {
                    if (currKeyFlag == -1)
                    { // first time
                        currKeyFlag = data.CurrKey;
                    }
                    // check if current row currKey same as previous, if not disable origTransAmt checking
                    else if (currKeyFlag != data.CurrKey && headerData.Source != "GJ")
                    {
                        enableOrigAmtCheck = false;
                    }

                    //DM now need checking also

                    //if (headerData.Source == "DM")
                    //{
                    //    //added by cianshu, do not validate for Data Migration Journal
                    //    enableOrigAmtCheck = false;
                    //}

                    currKeyFlag = data.CurrKey;

                    if (data.Ind != "IA")
                    {
                        if (headerData.EnableRoundingAdj == false && data.Ind == "RA")
                        {
                            //do nothing
                        }
                        else
                        {
                            // check for original amount != 0
                            if (headerData.Source == "GJ" && headerData.MultiCurrency)
                            {
                                accuOrigAmt = accuOrigAmt.Nadd2(data.FuncTransAmt);
                            }
                            else
                            {
                                accuOrigAmt = accuOrigAmt.Nadd2(data.OrigTransAmt);
                            }
                        }
                    }

                    if (headerData.Source == "GJ")
                    {
                        // only accumulate positive amount to check against docAmt
                        if ((data.OrigTransAmt.NtoDP(2) > 0 && (data.Ind != "A" && data.Ind != "AO" && data.Ind != 'IA')))
                        {
                            accuDocAmt = accuDocAmt.Nadd2(data.OrigTransAmt);
                        }
                    }
                    else
                    {
                        // only accumulate positive amount to check against docAmt
                        if ((data.OrigTransAmt.NtoDP(2) > 0 && (data.Ind != "A" && data.Ind != "AO" && data.Ind != 'IA')) || data.Ind.startsWith("T"))
                        {
                            accuDocAmt = accuDocAmt.Nadd2(data.OrigTransAmt);
                        }
                    }
                }

                // check for functional amount != 0
                accuFuncAmt = accuFuncAmt.Nadd2(data.FuncTransAmt);
            }
        });

        // if break loop means checking fails
        if (breakLoop)
        {
            return ErrorMsg;
        }

        //////////////////////////////////
        // Original Amount sum must be 0
        //////////////////////////////////
        if (accuOrigAmt.NtoDP(2) != 0 && enableOrigAmtCheck)
        {
            ErrorMsg = "The total value of the debit does not equal the total value of the credit";
        }

        return ErrorMsg;
    };

    //record validation on mandotary field's validity
    let _fieldValidation = function (formInput, vendorType, headerData, detailContactData, docAmt, detData, allocData, finSettingData, FuncCurrKey, OTVendorData)
    {
        if (typeof FuncCurrKey === 'undefined')
        {
            FuncCurrKey = headerData.CurrKey;
        }

        if (typeof OTVendorData === 'undefined')
        {
            OTVendorData = "";
        }

        let langKey = $rootScope.userSetting.langKey;

        //description
        let blnCategoryError = [false, false, false, false];
        let strCategoryError = ["", "", "", ""];

        let blnError = false;
        let strError = "";
        let strError2 = "";

        //general checking
        for (var i = 0; i < blnCategoryError.length; i++)
        {
            //Beginning Of Next Category
            strError = "";
            strError2 = "";

            if (i == 0)
            {
                //check on Category 1
                if (formInput.GLDesc.$invalid)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }
                    strError = $rootScope.label['68'][langKey].LabelDesc + ", ";
                }

                if (blnCategoryError[i])
                {
                    if (strError.length > 0)
                    {
                        strError = strError.substring(0, strError.length - 2);
                    }
                    strCategoryError[i] += "<strong>1.&nbsp;" + $rootScope.label['788'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [strError]);
                }
            }
            else if (i == 1)
            {
                //Second Category
                if (detailContactData.CCIDKey <= 0)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    if (vendorType === "supplier")
                    {
                        strError += $rootScope.label['37'][langKey].LabelDesc + ", ";
                    }
                    else
                    {
                        strError += $rootScope.label['303'][langKey].LabelDesc + ", ";
                    }
                }
                if (headerData.Source === "PI" || ((headerData.Source === "CN" || headerData.Source === "DN") && headerData.CDType !== "GEN") || headerData.Source === "SBR")
                {
                    if (headerData.InvNum === "" || typeof headerData.InvNum !== "string")
                    {
                        blnError = true;
                        if (!blnCategoryError[i])
                        {
                            blnCategoryError[i] = true;
                        }

                        if ($rootScope.CtryVersion == 'M')
                        {
                            strError += $rootScope.label['305'][langKey].LabelDesc + ", ";
                        }
                        else if ($rootScope.CtryVersion == 'I' && headerData.Source === "PI")
                        {
                            strError += $rootScope.label['305'][langKey].LabelDesc + ", ";
                        }
                        else if ($rootScope.CtryVersion == 'I' && headerData.Source === "DN")
                        {
                            strError += $rootScope.label['7910'][langKey].LabelDesc + ", ";
                        }
                        else if ($rootScope.CtryVersion == 'I' && headerData.Source === "CN")
                        {
                            strError += $rootScope.label['1122'][langKey].LabelDesc + ", ";
                        }
                    }
                }
                if (headerData.PayTermKey <= 0)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }
                    strError += $rootScope.label['196'][langKey].LabelDesc + ", ";
                }
                if (formInput.Remarks.$invalid)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }
                    strError += $rootScope.label['4'][langKey].LabelDesc + ", ";
                }

                if (headerData.Source === "PI")
                {
                    if (OTVendorData.Type == "EXT" || OTVendorData.Type == "INT")
                    {
                        if ((OTVendorData.Type == "EXT" && OTVendorData.VendorName == "") || (OTVendorData.Type == "INT" && OTVendorData.EmpyKey == -1))
                        {
                            blnError = true;
                            if (!blnCategoryError[i])
                            {
                                blnCategoryError[i] = true;
                            }
                            strError += $rootScope.label['302'][langKey].LabelDesc + ", ";
                        }
                    }
                }

                if (docAmt <= 0)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }
                    strError2 += $rootScope.label['583'][langKey].LabelDesc;
                }

                if (blnCategoryError[i])
                {
                    if (vendorType === "supplier")
                    {
                        strCategoryError[i] += "<strong>2.&nbsp;" + $rootScope.label['442'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;";
                    }
                    else
                    {
                        strCategoryError[i] += "<strong>2.&nbsp;" + $rootScope.label['578'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;";
                    }

                    if (strError.length > 0)
                    {
                        strError = strError.substring(0, strError.length - 2);
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [strError]);
                    }

                    if (strError2.length > 0)
                    {
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['12'][langKey].MsgBody, [strError2]);
                    }
                }
            }
            else if (i == 2)
            {
                //Similar to top condition
                let errCur = "", errRate = "";

                if (headerData.CurrKey <= 0 || headerData.CurrKey == "")
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    errCur = $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [$rootScope.label['38'][langKey].LabelDesc]);
                }

                if (headerData.ExRateToFunc <= 0 || headerData.ExRateToFunc == "")
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }
                    errRate += $filter('stringFormat')($rootScope.msg['12'][langKey].MsgBody, [$rootScope.label['39'][langKey].LabelDesc]);
                }

                if (blnCategoryError[i])
                {
                    strCategoryError[i] += "<strong>3.&nbsp;" + $rootScope.label['581'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + errCur + " " + errRate;
                }
            }
            else if (i == 3)
            {
                //Special Checking For Detail Grid
                if (detData.length <= 1)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    strCategoryError[i] += "<strong>4.&nbsp;" + $rootScope.label['790'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $rootScope.msg['7'][langKey].MsgBody;
                }
                else if (headerData.CurrKey != FuncCurrKey && allocData.length > 0)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    strCategoryError[i] += "<strong>4.&nbsp;" + $rootScope.label['790'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $rootScope.label['7358'][langKey].LabelDesc;
                }
                else
                {
                    let CurrentRecordValidation = _currentRecordValidation(headerData, detData, allocData, finSettingData);
                    if (CurrentRecordValidation != "")
                    {
                        blnError = true;
                        if (!blnCategoryError[i])
                        {
                            blnCategoryError[i] = true;
                        }
                        strCategoryError[i] += "<strong>4.&nbsp;" + $rootScope.label['790'][langKey].LabelDesc + " (" + $rootScope.label['265'][langKey].LabelDesc + ")</strong>:&nbsp;&nbsp;" + CurrentRecordValidation;
                    }
                }
            }
        }

        return {
            blnError: blnError,
            blnCategoryError: blnCategoryError,
            strCategoryError: strCategoryError
        };
    };

    let _fieldValidationGJ = function (formInput, headerData, detData, allocData, finSettingData, FuncCurrKey)
    {
        if (typeof FuncCurrKey === 'undefined')
        {
            FuncCurrKey = headerData.CurrKey;
        }

        let langKey = $rootScope.userSetting.langKey;

        //description
        let blnCategoryError = [false, false, false, false];
        let strCategoryError = ["", "", "", ""];

        let blnError = false;
        let strError = "";
        let strError2 = "";

        //general checking
        for (var i = 0; i < blnCategoryError.length; i++)
        {
            //Beginning Of Next Category
            strError = "";
            strError2 = "";

            if (i == 0)
            {
                //check on Category 1
                if (formInput.GLDesc.$invalid)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }
                    strError = $rootScope.label['68'][langKey].LabelDesc + ", ";
                }

                if (blnCategoryError[i])
                {
                    if (strError.length > 0)
                    {
                        strError = strError.substring(0, strError.length - 2);
                    }
                    strCategoryError[i] += "<strong>1.&nbsp;" + $rootScope.label['788'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [strError]);
                }
            }
            else if (i == 1)
            {
                //Similar to top condition
                let errCur = "", errRate = "";

                if (headerData.CurrKey <= 0 || headerData.CurrKey == "")
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    errCur = $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [$rootScope.label['38'][langKey].LabelDesc]);
                }

                if (headerData.ExRateToFunc <= 0 || headerData.ExRateToFunc == "")
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }
                    errRate += $filter('stringFormat')($rootScope.msg['12'][langKey].MsgBody, [$rootScope.label['39'][langKey].LabelDesc]);
                }

                if (blnCategoryError[i])
                {
                    strCategoryError[i] += "<strong>2.&nbsp;" + $rootScope.label['581'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + errCur + " " + errRate;
                }
            }
            else if (i == 2)
            {
                //Special Checking For Detail Grid
                //GJ no v line
                if (detData.length <= 0)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    strCategoryError[i] += "<strong>3.&nbsp;" + $rootScope.label['790'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $rootScope.msg['7'][langKey].MsgBody;
                }

                else if ((headerData.MultiCurrency == true || headerData.CurrKey != FuncCurrKey) && allocData.length > 0)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    strCategoryError[i] += "<strong>3.&nbsp;" + $rootScope.label['790'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $rootScope.label['7358'][langKey].LabelDesc;
                }
                else
                {
                    let CurrentRecordValidation = _currentRecordValidation(headerData, detData, allocData, finSettingData);
                    if (CurrentRecordValidation != "")
                    {
                        blnError = true;
                        if (!blnCategoryError[i])
                        {
                            blnCategoryError[i] = true;
                        }
                        strCategoryError[i] += "<strong>3.&nbsp;" + $rootScope.label['790'][langKey].LabelDesc + " (" + $rootScope.label['265'][langKey].LabelDesc + ")</strong>:&nbsp;&nbsp;" + CurrentRecordValidation;
                    }
                }
            }
        }

        return {
            blnError: blnError,
            blnCategoryError: blnCategoryError,
            strCategoryError: strCategoryError
        };
    };

    let _fieldValidationBC = function (formInput, headerData, bcData, docAmt, detData, allocData, finSettingData, FuncCurrKey)
    {
        if (typeof FuncCurrKey === 'undefined')
        {
            FuncCurrKey = headerData.CurrKey;
        }

        let langKey = $rootScope.userSetting.langKey;

        //description
        let blnCategoryError = [false, false, false, false];
        let strCategoryError = ["", "", "", ""];

        let blnError = false;
        let strError = "";
        let strError2 = "";

        //general checking
        for (var i = 0; i < blnCategoryError.length; i++)
        {
            //Beginning Of Next Category
            strError = "";
            strError2 = "";

            if (i == 0)
            {
                //check on Category 1
                if (formInput.GLDesc.$invalid)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }
                    strError = $rootScope.label['68'][langKey].LabelDesc + ", ";
                }

                if (blnCategoryError[i])
                {
                    if (strError.length > 0)
                    {
                        strError = strError.substring(0, strError.length - 2);
                    }
                    strCategoryError[i] += "<strong>1.&nbsp;" + $rootScope.label['788'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [strError]);
                }
            }
            else if (i == 1)
            {
                //Second Category
                if (headerData.Source === "CP" || headerData.Source === "CR")
                {
                    if (bcData.CashKey <= 0)
                    {
                        blnError = true;
                        if (!blnCategoryError[i])
                        {
                            blnCategoryError[i] = true;
                        }

                        strError += $rootScope.label['312'][langKey].LabelDesc + ", ";
                    }
                }

                if (headerData.Source === "BP" || headerData.Source === "BR")
                {
                    if (bcData.BankKey <= 0)
                    {
                        blnError = true;
                        if (!blnCategoryError[i])
                        {
                            blnCategoryError[i] = true;
                        }

                        strError += $rootScope.label['273'][langKey].LabelDesc + ", ";
                    }
                }

                if (formInput.Remarks.$invalid)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }
                    strError += $rootScope.label['4'][langKey].LabelDesc + ", ";
                }

                if (docAmt <= 0)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    if (headerData.Source === "BR" || headerData.Source === "CR")
                    {
                        strError2 += $rootScope.label['617'][langKey].LabelDesc;
                    }
                    else if (headerData.Source === "BP" || headerData.Source === "CP")
                    {
                        strError2 += $rootScope.label['279'][langKey].LabelDesc;
                    }
                }

                if (blnCategoryError[i])
                {
                    if (headerData.Source === "BR" || headerData.Source === "CR")
                    {
                        strCategoryError[i] += "<strong>2.&nbsp;" + $rootScope.label['793'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;";
                    }
                    else if (headerData.Source === "BP" || headerData.Source === "CP")
                    {
                        strCategoryError[i] += "<strong>2.&nbsp;" + $rootScope.label['795'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;";
                    }

                    if (strError.length > 0)
                    {
                        strError = strError.substring(0, strError.length - 2);
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [strError]);
                    }

                    if (strError2.length > 0)
                    {
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['12'][langKey].MsgBody, [strError2]);
                    }
                }
            }
            else if (i == 2)
            {
                //Similar to top condition
                let errCur = "", errRate = "";

                if (headerData.CurrKey <= 0 || headerData.CurrKey == "")
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    errCur += $rootScope.label['38'][langKey].LabelDesc + ", ";
                }

                if (headerData.ExRateToFunc <= 0 || headerData.ExRateToFunc == "")
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    errRate += $rootScope.label['39'][langKey].LabelDesc + ", ";
                }

                if (headerData.MultiCurrency == true && (headerData.Source === "BP" || headerData.Source === "CP"))
                {
                    if (headerData.ForeignCurrKey <= 0 || headerData.ForeignCurrKey == "")
                    {
                        blnError = true;
                        if (!blnCategoryError[i])
                        {
                            blnCategoryError[i] = true;
                        }

                        errCur += $rootScope.label['4340'][langKey].LabelDesc + ", ";
                    }
                    if (headerData.ForeignExRate <= 0 || headerData.ForeignExRate == "")
                    {
                        blnError = true;
                        if (!blnCategoryError[i])
                        {
                            blnCategoryError[i] = true;
                        }

                        errRate += $rootScope.label['4341'][langKey].LabelDesc + ", ";
                    }
                    if (headerData.ForeignExRateLocal <= 0 || headerData.ForeignExRateLocal == "")
                    {
                        blnError = true;
                        if (!blnCategoryError[i])
                        {
                            blnCategoryError[i] = true;
                        }

                        errRate += $rootScope.label['4504'][langKey].LabelDesc + ", ";
                    }
                }

                if (blnCategoryError[i])
                {
                    strCategoryError[i] += "<strong>3.&nbsp;" + $rootScope.label['581'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;";

                    if (errCur.length > 0)
                    {
                        errCur = errCur.substring(0, errCur.length - 2);
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [errCur]);
                    }

                    if (errRate.length > 0)
                    {
                        errRate = errRate.substring(0, errRate.length - 2);
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['12'][langKey].MsgBody, [errRate]);
                    }
                }
            }
            else if (i == 3)
            {
                //Special Checking For Detail Grid
                if (detData.length <= 1)
                {
                    let oLineFlag = false;
                    angular.forEach(detData, function (data, index)
                    {
                        if (data.Ind == "O" || data.Ind == "V")
                            oLineFlag = true;
                    });

                    if (!oLineFlag)
                    {
                        blnError = true;
                        if (!blnCategoryError[i])
                        {
                            blnCategoryError[i] = true;
                        }

                        strCategoryError[i] += "<strong>4.&nbsp;" + $rootScope.label['790'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $rootScope.msg['7'][langKey].MsgBody;
                    }
                }
                else if ((headerData.MultiCurrency == true || headerData.CurrKey != FuncCurrKey) && allocData.length > 0)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    strCategoryError[i] += "<strong>4.&nbsp;" + $rootScope.label['790'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $rootScope.label['7358'][langKey].LabelDesc;
                }
                else
                {
                    let CurrentRecordValidation = _currentRecordValidation(headerData, detData, allocData, finSettingData);
                    if (CurrentRecordValidation != "")
                    {
                        blnError = true;
                        if (!blnCategoryError[i])
                        {
                            blnCategoryError[i] = true;
                        }
                        strCategoryError[i] += "<strong>4.&nbsp;" + $rootScope.label['790'][langKey].LabelDesc + " (" + $rootScope.label['265'][langKey].LabelDesc + ")</strong>:&nbsp;&nbsp;" + CurrentRecordValidation;
                    }
                }
            }
        }

        return {
            blnError: blnError,
            blnCategoryError: blnCategoryError,
            strCategoryError: strCategoryError
        };
    };

    let _fieldValidationDC = function (formInput, vendorType, headerData, detailContactData, bcData, docAmt, detData)
    {
        let langKey = $rootScope.userSetting.langKey;

        //description
        let blnCategoryError = [false, false, false, false, false];
        let strCategoryError = ["", "", "", "", ""];

        let blnError = false;
        let strError = "";
        let strError2 = "";

        //general checking
        for (var i = 0; i < blnCategoryError.length; i++)
        {
            //Beginning Of Next Category
            strError = "";
            strError2 = "";

            if (i == 0)
            {
                //check on Category 1
                if (formInput.GLDesc.$invalid)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }
                    strError = $rootScope.label['68'][langKey].LabelDesc + ", ";
                }

                if (blnCategoryError[i])
                {
                    if (strError.length > 0)
                    {
                        strError = strError.substring(0, strError.length - 2);
                    }
                    strCategoryError[i] += "<strong>1.&nbsp;" + $rootScope.label['788'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [strError]);
                }
            }
            else if (i == 1)
            {
                //Second Category
                if (detailContactData.CCIDKey <= 0)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    if (vendorType === "supplier")
                    {
                        strError += $rootScope.label['37'][langKey].LabelDesc + ", ";
                    }
                    else
                    {
                        strError += $rootScope.label['303'][langKey].LabelDesc + ", ";
                    }
                }

                if (blnCategoryError[i])
                {
                    if (vendorType === "supplier")
                    {
                        strCategoryError[i] += "<strong>2.&nbsp;" + $rootScope.label['442'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;";
                    }
                    else
                    {
                        strCategoryError[i] += "<strong>2.&nbsp;" + $rootScope.label['578'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;";
                    }

                    if (strError.length > 0)
                    {
                        strError = strError.substring(0, strError.length - 2);
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [strError]);
                    }
                }
            }
            else if (i == 2)
            {
                if (bcData.BankKey <= 0)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    if (bcData.PaymentCategory == 'B')
                    {
                        strError += $rootScope.label['273'][langKey].LabelDesc + ", ";
                    }
                    else if (bcData.PaymentCategory == 'C')
                    {
                        strError += $rootScope.label['312'][langKey].LabelDesc + ", ";
                    }
                }

                if (formInput.Remarks.$invalid)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }
                    strError += $rootScope.label['4'][langKey].LabelDesc + ", ";
                }

                if (docAmt < 0)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    //if (headerData.Source === "DRP")
                    //{
                    //    strError2 += $rootScope.label['617'][langKey].LabelDesc;
                    //}
                    //else if (headerData.Source === "CRP")
                    //{
                    //    strError2 += $rootScope.label['279'][langKey].LabelDesc;
                    //}

                    strError2 += $rootScope.label['279'][langKey].LabelDesc;
                }

                if (blnCategoryError[i])
                {
                    strCategoryError[i] += "<strong>3.&nbsp;" + $rootScope.label['789'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;"

                    if (strError.length > 0)
                    {
                        strError = strError.substring(0, strError.length - 2);
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [strError]);
                    }

                    if (strError2.length > 0)
                    {
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['74'][langKey].MsgBody, [strError2, "zero"]);
                    }
                }
            }
            else if (i == 3)
            {
                //Similar to top condition
                let errCur = "", errRate = "";

                if (headerData.CurrKey <= 0 || headerData.CurrKey == "")
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    errCur += $rootScope.label['38'][langKey].LabelDesc + ", ";
                }

                if (headerData.ExRateToFunc <= 0 || headerData.ExRateToFunc == "")
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    errRate += $rootScope.label['39'][langKey].LabelDesc + ", ";
                }

                if (headerData.MultiCurrency == true && (headerData.Source === "CRP" || headerData.Source === "DRP"))
                {
                    if (headerData.ForeignCurrKey <= 0 || headerData.ForeignCurrKey == "")
                    {
                        blnError = true;
                        if (!blnCategoryError[i])
                        {
                            blnCategoryError[i] = true;
                        }

                        errCur += $rootScope.label['4340'][langKey].LabelDesc + ", ";
                    }
                    if (headerData.ForeignExRate <= 0 || headerData.ForeignExRate == "")
                    {
                        blnError = true;
                        if (!blnCategoryError[i])
                        {
                            blnCategoryError[i] = true;
                        }

                        errRate += $rootScope.label['4341'][langKey].LabelDesc + ", ";
                    }
                    if (headerData.ForeignExRateLocal <= 0 || headerData.ForeignExRateLocal == "")
                    {
                        blnError = true;
                        if (!blnCategoryError[i])
                        {
                            blnCategoryError[i] = true;
                        }

                        errRate += $rootScope.label['4504'][langKey].LabelDesc + ", ";
                    }
                }

                if (blnCategoryError[i])
                {
                    strCategoryError[i] += "<strong>4.&nbsp;" + $rootScope.label['581'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;";

                    if (errCur.length > 0)
                    {
                        errCur = errCur.substring(0, errCur.length - 2);
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [errCur]);
                    }

                    if (errRate.length > 0)
                    {
                        errRate = errRate.substring(0, errRate.length - 2);
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['12'][langKey].MsgBody, [errRate]);
                    }
                }
            }
            else if (i == 4)
            {
                //Special Checking For Detail Grid
                let isAppliedZero = false;
                let isWrongSign = false;
                let isOverApplied = false;

                angular.forEach(detData, function (data, index)
                {
                    if (data.Ind == "O" || data.Ind == "OP")
                    {
                        if (data.AppliedAmt == 0)
                        {
                            isAppliedZero = true;
                        }

                        if (data.OpenAmt != 0 && data.AppliedAmt != 0 && data.AppliedAmt.Ntimes(data.OpenAmt) < 0)
                        {
                            isWrongSign = true;
                        }
                        else if (data.OpenAmt != 0 && data.AppliedAmt != 0 && data.AppliedAmt.Ntimes(data.OpenAmt) > 0)
                        {
                            if (data.AppliedAmt.Nabs() > data.OpenAmt.Nabs())
                            {
                                isOverApplied = true;
                            }
                        }
                    }
                });

                if (detData.length <= 0)
                {
                    let oLineFlag = false;
                    angular.forEach(detData, function (data, index)
                    {
                        if (data.Ind == "O" || data.Ind == "OP")
                            oLineFlag = true;
                    });

                    if (!oLineFlag)
                    {
                        blnError = true;
                        if (!blnCategoryError[i])
                        {
                            blnCategoryError[i] = true;
                        }

                        strCategoryError[i] += "<strong>5.&nbsp;" + $rootScope.label['790'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $rootScope.msg['7'][langKey].MsgBody;
                    }
                }
                else if (isAppliedZero)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    strCategoryError[i] += "<strong>5.&nbsp;" + $rootScope.label['790'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $filter('stringFormat')($rootScope.msg['370'][langKey].MsgBody, [$rootScope.label['616'][langKey].LabelDesc]);
                }
                else if (isWrongSign)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    strCategoryError[i] += "<strong>5.&nbsp;" + $rootScope.label['790'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $rootScope.label['4190'][langKey].LabelDesc + ".";
                }
                else if (isOverApplied)
                {
                    blnError = true;
                    if (!blnCategoryError[i])
                    {
                        blnCategoryError[i] = true;
                    }

                    strCategoryError[i] += "<strong>5.&nbsp;" + $rootScope.label['790'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $rootScope.label['4192'][langKey].LabelDesc + ".";
                }
                else
                {
                    //let CurrentRecordValidation = _currentRecordValidation(headerData, detData);
                    //if (CurrentRecordValidation != "")
                    //{
                    //    blnError = true;
                    //    if (!blnCategoryError[i])
                    //    {
                    //        blnCategoryError[i] = true;
                    //    }
                    //    strCategoryError[i] += "<strong>5.&nbsp;" + $rootScope.label['790'][langKey].LabelDesc + " (" + $rootScope.label['265'][langKey].LabelDesc + ")</strong>:&nbsp;&nbsp;" + CurrentRecordValidation;
                    //}
                }
            }
        }

        return {
            blnError: blnError,
            blnCategoryError: blnCategoryError,
            strCategoryError: strCategoryError
        };
    };

    let _fieldValidationSkipDet = function (formInput, vendorType, headerData, detailContactData, FuncCurrKey, OTVendorData) {
        if (typeof FuncCurrKey === 'undefined') {
            FuncCurrKey = headerData.CurrKey;
        }

        if (typeof OTVendorData === 'undefined') {
            OTVendorData = "";
        }

        let langKey = $rootScope.userSetting.langKey;

        //description
        let blnCategoryError = [false, false, false, false];
        let strCategoryError = ["", "", "", ""];

        let blnError = false;
        let strError = "";
        let strError2 = "";

        //general checking
        for (var i = 0; i < blnCategoryError.length; i++) {
            //Beginning Of Next Category
            strError = "";
            strError2 = "";

            if (i == 0) {
                //check on Category 1
                if (formInput.GLDesc.$invalid) {
                    blnError = true;
                    blnCategoryError[i] = true;
                    strError = $rootScope.label['68'][langKey].LabelDesc + ", ";
                }

                if (blnCategoryError[i]) {
                    if (strError.length > 0) {
                        strError = strError.substring(0, strError.length - 2);
                    }
                    strCategoryError[i] += "<strong>1.&nbsp;" + $rootScope.label['788'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [strError]);
                }
            }
            else if (i == 1) {
                //Second Category
                if (detailContactData.CCIDKey <= 0) {
                    blnError = true;
                    blnCategoryError[i] = true;

                    if (vendorType === "supplier") {
                        strError += $rootScope.label['37'][langKey].LabelDesc + ", ";
                    }
                    else {
                        strError += $rootScope.label['303'][langKey].LabelDesc + ", ";
                    }
                }
                if (headerData.Source === "PI" || ((headerData.Source === "CN" || headerData.Source === "DN") && headerData.CDType !== "GEN") || headerData.Source === "SBR") {
                    if (headerData.InvNum === "" || typeof headerData.InvNum !== "string") {
                        blnError = true;
                        blnCategoryError[i] = true;

                        if ($rootScope.CtryVersion == 'M') {
                            strError += $rootScope.label['305'][langKey].LabelDesc + ", ";
                        }
                        else if ($rootScope.CtryVersion == 'I' && headerData.Source === "PI") {
                            strError += $rootScope.label['305'][langKey].LabelDesc + ", ";
                        }
                        else if ($rootScope.CtryVersion == 'I' && headerData.Source === "DN") {
                            strError += $rootScope.label['7910'][langKey].LabelDesc + ", ";
                        }
                        else if ($rootScope.CtryVersion == 'I' && headerData.Source === "CN") {
                            strError += $rootScope.label['1122'][langKey].LabelDesc + ", ";
                        }
                    }
                }
                if (headerData.PayTermKey <= 0) {
                    blnError = true;
                    blnCategoryError[i] = true;
                    strError += $rootScope.label['196'][langKey].LabelDesc + ", ";
                }
                if (formInput.Remarks.$invalid) {
                    blnError = true;
                    blnCategoryError[i] = true;
                    strError += $rootScope.label['4'][langKey].LabelDesc + ", ";
                }

                if (headerData.Source === "PI") {
                    if (OTVendorData.Type == "EXT" || OTVendorData.Type == "INT") {
                        if ((OTVendorData.Type == "EXT" && OTVendorData.VendorName == "") || (OTVendorData.Type == "INT" && OTVendorData.EmpyKey == -1)) {
                            blnError = true;
                            blnCategoryError[i] = true;
                            strError += $rootScope.label['302'][langKey].LabelDesc + ", ";
                        }
                    }
                }
                

                if (blnCategoryError[i]) {
                    if (vendorType === "supplier") {
                        strCategoryError[i] += "<strong>2.&nbsp;" + $rootScope.label['442'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;";
                    }
                    else {
                        strCategoryError[i] += "<strong>2.&nbsp;" + $rootScope.label['578'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;";
                    }

                    if (strError.length > 0) {
                        strError = strError.substring(0, strError.length - 2);
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [strError]);
                    }

                    if (strError2.length > 0) {
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['12'][langKey].MsgBody, [strError2]);
                    }
                }
            }
            else if (i == 2) {
                //Similar to top condition
                let errCur = "", errRate = "";

                if (headerData.CurrKey <= 0 || headerData.CurrKey == "") {
                    blnError = true;
                    blnCategoryError[i] = true;

                    errCur = $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [$rootScope.label['38'][langKey].LabelDesc]);
                }

                if (headerData.ExRateToFunc <= 0 || headerData.ExRateToFunc == "") {
                    blnError = true;
                    blnCategoryError[i] = true;
                    errRate += $filter('stringFormat')($rootScope.msg['12'][langKey].MsgBody, [$rootScope.label['39'][langKey].LabelDesc]);
                }

                if (blnCategoryError[i]) {
                    strCategoryError[i] += "<strong>3.&nbsp;" + $rootScope.label['581'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + errCur + " " + errRate;
                }
            }
            
        }

        return {
            blnError: blnError,
            blnCategoryError: blnCategoryError,
            strCategoryError: strCategoryError
        };
    };

    let _fieldValidationBCSkipDet = function (formInput, headerData, bcData, docAmt) {
        if (typeof FuncCurrKey === 'undefined') {
            FuncCurrKey = headerData.CurrKey;
        }

        let langKey = $rootScope.userSetting.langKey;

        //description
        let blnCategoryError = [false, false, false, false];
        let strCategoryError = ["", "", "", ""];

        let blnError = false;
        let strError = "";
        let strError2 = "";

        //general checking
        for (var i = 0; i < blnCategoryError.length; i++) {
            //Beginning Of Next Category
            strError = "";
            strError2 = "";

            if (i == 0) {
                //check on Category 1
                if (formInput.GLDesc.$invalid) {
                    blnError = true;
                    if (!blnCategoryError[i]) {
                        blnCategoryError[i] = true;
                    }
                    strError = $rootScope.label['68'][langKey].LabelDesc + ", ";
                }

                if (blnCategoryError[i]) {
                    if (strError.length > 0) {
                        strError = strError.substring(0, strError.length - 2);
                    }
                    strCategoryError[i] += "<strong>1.&nbsp;" + $rootScope.label['788'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [strError]);
                }
            }
            else if (i == 1) {
                //Second Category
                if (headerData.Source === "CP" || headerData.Source === "CR") {
                    if (bcData.CashKey <= 0) {
                        blnError = true;
                        if (!blnCategoryError[i]) {
                            blnCategoryError[i] = true;
                        }

                        strError += $rootScope.label['312'][langKey].LabelDesc + ", ";
                    }
                }

                if (headerData.Source === "BP" || headerData.Source === "BR") {
                    if (bcData.BankKey <= 0) {
                        blnError = true;
                        if (!blnCategoryError[i]) {
                            blnCategoryError[i] = true;
                        }

                        strError += $rootScope.label['273'][langKey].LabelDesc + ", ";
                    }
                }

                if (formInput.Remarks.$invalid) {
                    blnError = true;
                    if (!blnCategoryError[i]) {
                        blnCategoryError[i] = true;
                    }
                    strError += $rootScope.label['4'][langKey].LabelDesc + ", ";
                }

                if (docAmt <= 0) {
                    blnError = true;
                    if (!blnCategoryError[i]) {
                        blnCategoryError[i] = true;
                    }

                    if (headerData.Source === "BR" || headerData.Source === "CR") {
                        strError2 += $rootScope.label['617'][langKey].LabelDesc;
                    }
                    else if (headerData.Source === "BP" || headerData.Source === "CP") {
                        strError2 += $rootScope.label['279'][langKey].LabelDesc;
                    }
                }

                if (blnCategoryError[i]) {
                    if (headerData.Source === "BR" || headerData.Source === "CR") {
                        strCategoryError[i] += "<strong>2.&nbsp;" + $rootScope.label['793'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;";
                    }
                    else if (headerData.Source === "BP" || headerData.Source === "CP") {
                        strCategoryError[i] += "<strong>2.&nbsp;" + $rootScope.label['795'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;";
                    }

                    if (strError.length > 0) {
                        strError = strError.substring(0, strError.length - 2);
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [strError]);
                    }

                    if (strError2.length > 0) {
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['12'][langKey].MsgBody, [strError2]);
                    }
                }
            }
            else if (i == 2) {
                //Similar to top condition
                let errCur = "", errRate = "";

                if (headerData.CurrKey <= 0 || headerData.CurrKey == "") {
                    blnError = true;
                    if (!blnCategoryError[i]) {
                        blnCategoryError[i] = true;
                    }

                    errCur += $rootScope.label['38'][langKey].LabelDesc + ", ";
                }

                if (headerData.ExRateToFunc <= 0 || headerData.ExRateToFunc == "") {
                    blnError = true;
                    if (!blnCategoryError[i]) {
                        blnCategoryError[i] = true;
                    }

                    errRate += $rootScope.label['39'][langKey].LabelDesc + ", ";
                }

                if (headerData.MultiCurrency == true && (headerData.Source === "BP" || headerData.Source === "CP")) {
                    if (headerData.ForeignCurrKey <= 0 || headerData.ForeignCurrKey == "") {
                        blnError = true;
                        if (!blnCategoryError[i]) {
                            blnCategoryError[i] = true;
                        }

                        errCur += $rootScope.label['4340'][langKey].LabelDesc + ", ";
                    }
                    if (headerData.ForeignExRate <= 0 || headerData.ForeignExRate == "") {
                        blnError = true;
                        if (!blnCategoryError[i]) {
                            blnCategoryError[i] = true;
                        }

                        errRate += $rootScope.label['4341'][langKey].LabelDesc + ", ";
                    }
                    if (headerData.ForeignExRateLocal <= 0 || headerData.ForeignExRateLocal == "") {
                        blnError = true;
                        if (!blnCategoryError[i]) {
                            blnCategoryError[i] = true;
                        }

                        errRate += $rootScope.label['4504'][langKey].LabelDesc + ", ";
                    }
                }

                if (blnCategoryError[i]) {
                    strCategoryError[i] += "<strong>3.&nbsp;" + $rootScope.label['581'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;";

                    if (errCur.length > 0) {
                        errCur = errCur.substring(0, errCur.length - 2);
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [errCur]);
                    }

                    if (errRate.length > 0) {
                        errRate = errRate.substring(0, errRate.length - 2);
                        strCategoryError[i] += $filter('stringFormat')($rootScope.msg['12'][langKey].MsgBody, [errRate]);
                    }
                }
            }
           
        }

        return {
            blnError: blnError,
            blnCategoryError: blnCategoryError,
            strCategoryError: strCategoryError
        };
    };

    //shared function
    let _updateVendorInfo = function (headerData, detailContactData, detData, convertType, isOpenTrans)
    {
        let vendAmt = 0;
        let vendExist = false;
        let vendIndex = -1;

        angular.forEach(detData, function (data, index)
        {
            if (data.Ind == "V")
            {
                vendExist = true;
                vendIndex = index;
            }
        });

        angular.forEach(detData, function (vdata, index)
        {
            //add RA into calculating the total vendor amount as well since in control service, ra was excluded by KY 06/11/2017
            if (vdata.Ind == "O" || vdata.Ind == "I" || vdata.Ind == "CW") //add in T line to merge T line with V line by Cianshu 15/12/2016
            {
                vendAmt = vendAmt.Nadd2(vdata.OrigTransAmt);
            }

            if (vdata.Ind == "RA")  //add in T line to merge T line with V line by Cianshu 15/12/2016
            {
                if (headerData.EnableRoundingAdj == true)
                {
                    vendAmt = vendAmt.Nadd2(vdata.OrigTransAmt);
                }
            }

            if (vdata.Ind.startsWith("T"))
            {
                //Since tax amount is in MYR, we need to flip it to other currency judging from the opposite of company convert type
                if (convertType == "/")
                {
                    vendAmt = vendAmt.Nadd2(vdata.OrigTransAmt.Ntimes(headerData.ExRateToFunc));
                }
                else if (convertType == "*")
                {
                    vendAmt = vendAmt.Nadd2(vdata.OrigTransAmt.Ndiv(headerData.ExRateToFunc));
                }
            }
        });

        if (vendExist)
        {
            detData[vendIndex].AccNum = detailContactData.AccNum;
            detData[vendIndex].AccKey = detailContactData.AccKey;
            detData[vendIndex].AccDesc = detailContactData.AccDesc;
            detData[vendIndex].ClientKey = detailContactData.ClientKey;
            detData[vendIndex].CurrKey = headerData.CurrKey;
            detData[vendIndex].CurrCode = headerData.CurrCode;
            detData[vendIndex].OUKey = headerData.OUKey;
            detData[vendIndex].OUCode = headerData.OUCode;
            detData[vendIndex].Remarks = detailContactData.Remarks;
            detData[vendIndex].FuncTransAmt = 0;
            detData[vendIndex].LocalTransAmt = 0;
            detData[vendIndex].OrigTransAmt = vendAmt.Nnegated2();
            detData[vendIndex].OrigDrAmt = (vendAmt.Nnegated2() > 0 ? vendAmt.Nnegated2() : 0);
            detData[vendIndex].OrigCrAmt = (vendAmt.Nnegated2() < 0 ? vendAmt.Nnegated2().Nabs() : 0);
            detData[vendIndex].SeqNo = 0;
            detData[vendIndex].ExRateFunc = headerData.ExRateToFunc;
            detData[vendIndex].ExRateLocal = headerData.ExRateToLocal;
            detData[vendIndex].CCID = detailContactData.CCID;
            detData[vendIndex].CCIDKey = detailContactData.ContactCCIDKey;
            detData[vendIndex].Qty = 0;
            detData[vendIndex].UOMKey = null;
            detData[vendIndex].UOM = "";
            detData[vendIndex].UnitPrice = 0;
            detData[vendIndex].Discount = 0;
            detData[vendIndex].DiscountAmt = 0;
            detData[vendIndex].Ind = "V";
            //detData[vendIndex].RefTransDetKey = null;
            //detData[vendIndex].RefTransHdrKey = null;
            //detData[vendIndex].APARRefTransHdrKey = null;
            //detData[vendIndex].APARRefTransDetKey = null;
            //detData[vendIndex].AdjAccKey = -1;
            //detData[vendIndex].AdjCCIDKey = -1;
            //detData[vendIndex].AdjAmount = 0;
            detData[vendIndex].TaxPer = 0;
            detData[vendIndex].RefTax = "";
            detData[vendIndex].IsSelect = false;

            if (isOpenTrans === false)
            {
                detData[vendIndex].dirty = true;
            }
        }
        else
        {
            detData.push({
                TransDetKey: 0,
                TransHdrKey: 0,
                AccNum: detailContactData.AccNum,
                AccKey: detailContactData.AccKey,
                AccDesc: detailContactData.AccDesc,
                ClientKey: detailContactData.ClientKey,
                CurrKey: headerData.CurrKey,
                CurrCode: headerData.CurrCode,
                OUKey: headerData.OUKey,
                OUCode: headerData.OUCode,
                Remarks: detailContactData.Remarks,
                FuncTransAmt: 0,
                LocalTransAmt: 0,
                OrigTransAmt: vendAmt.Nnegated2(),
                OrigDrAmt: (vendAmt.Nnegated2() > 0 ? vendAmt.Nnegated2() : 0),
                OrigCrAmt: (vendAmt.Nnegated2() < 0 ? vendAmt.Nnegated2().Nabs() : 0),
                SeqNo: 0,
                ExRateFunc: headerData.ExRateToFunc,
                ExRateLocal: headerData.ExRateToLocal,
                CCID: detailContactData.CCID,
                CCIDKey: detailContactData.ContactCCIDKey,
                Qty: 0,
                UOMKey: null,
                UOM: "",
                UnitPrice: 0,
                Discount: 0,
                DiscountAmt: 0,
                Ind: "V",
                RefTransDetKey: null,
                RefTransHdrKey: null,
                APARRefTransHdrKey: null,
                APARRefTransDetKey: null,
                AdjAccKey: -1,
                AdjCCIDKey: -1,
                AdjAmount: 0,
                TaxPer: 0,
                RefTax: "",
                IsSelect: false,

                dirty: true
            });
        }
    };

    let _updateBankInfo = function (headerData, bcData, detData, convertType, isOpenTrans)
    {
        let vendAmt = 0;
        let vendExist = false;
        let vendIndex = -1;

        angular.forEach(detData, function (data, index)
        {
            if (data.Ind == "B")
            {
                vendExist = true;
                vendIndex = index;
            }
        });

        angular.forEach(detData, function (vdata, index)
        {
            //add RA into calculating the total vendor amount as well since in control service, ra was excluded by KY 06/11/2017
            if (vdata.Ind != "B" && vdata.Ind != "VR" && vdata.Ind != "RA" && !vdata.Ind.startsWith("T"))
            {
                if (vdata.CurrKey == headerData.CurrKey)
                {
                    vendAmt = vendAmt.Nadd2(vdata.OrigTransAmt);
                }
                else if (headerData.MultiCurrency == true && vdata.CurrKey == headerData.ForeignCurrKey)
                {
                    //for total payment we always let user decide the ratio and then convert the value using the ratio given
                    let convertRate = (headerData.ForeignExRate).Ndiv(headerData.ForeignExRateLocal);

                    vendAmt = vendAmt.Nadd2(vdata.OrigTransAmt.Ndiv(convertRate));
                }
            }

            if (vdata.Ind == "RA")  //add in T line to merge T line with V line by Cianshu 15/12/2016
            {
                if (headerData.EnableRoundingAdj == true)
                {
                    vendAmt = vendAmt.Nadd2(vdata.OrigTransAmt);
                }
            }

            if (vdata.Ind.startsWith("T"))
            {
                //Since tax amount is in MYR, we need to flip it to other currency judging from the opposite of company convert type
                if (convertType == "/")
                {
                    vendAmt = vendAmt.Nadd2(vdata.OrigTransAmt.Ntimes(headerData.ExRateToFunc));
                }
                else if (convertType == "*")
                {
                    vendAmt = vendAmt.Nadd2(vdata.OrigTransAmt.Ndiv(headerData.ExRateToFunc));
                }
            }

            if (bcData.PaymentCategory == "F")
            {
                vendAmt = vendAmt.Ntimes2(bcData.BuyingExRateFC);
            }
        });

        if (vendExist)
        {
            detData[vendIndex].AccNum = bcData.BankAcc;
            detData[vendIndex].AccKey = bcData.BankAccKey;
            detData[vendIndex].AccDesc = bcData.BankDesc;
            detData[vendIndex].ClientKey = bcData.ClientKey;
            detData[vendIndex].CurrKey = headerData.CurrKey;
            detData[vendIndex].CurrCode = headerData.CurrCode;
            detData[vendIndex].OUKey = headerData.OUKey;
            detData[vendIndex].OUCode = headerData.OUCode;
            detData[vendIndex].Remarks = headerData.Remarks;
            detData[vendIndex].FuncTransAmt = 0;
            detData[vendIndex].LocalTransAmt = 0;
            detData[vendIndex].OrigTransAmt = vendAmt.Nnegated2();
            detData[vendIndex].OrigDrAmt = (vendAmt.Nnegated2() > 0 ? vendAmt.Nnegated2() : 0);
            detData[vendIndex].OrigCrAmt = (vendAmt.Nnegated2() < 0 ? vendAmt.Nnegated2().Nabs() : 0);
            detData[vendIndex].SeqNo = 0;
            detData[vendIndex].ExRateFunc = headerData.ExRateToFunc;
            detData[vendIndex].ExRateLocal = headerData.ExRateToLocal;
            detData[vendIndex].CCID = bcData.BankCode;
            detData[vendIndex].CCIDKey = bcData.BankCCIDKey;
            detData[vendIndex].Qty = 0;
            detData[vendIndex].UOMKey = null;
            detData[vendIndex].UOM = "";
            detData[vendIndex].UnitPrice = 0;
            detData[vendIndex].Discount = 0;
            detData[vendIndex].DiscountAmt = 0;
            detData[vendIndex].Ind = "B";
            //detData[vendIndex].RefTransDetKey = null;
            //detData[vendIndex].RefTransHdrKey = null;
            //detData[vendIndex].APARRefTransHdrKey = null;
            //detData[vendIndex].APARRefTransDetKey = null;
            //detData[vendIndex].AdjAccKey = -1;
            //detData[vendIndex].AdjCCIDKey = -1;
            //detData[vendIndex].AdjAmount = 0;
            detData[vendIndex].TaxPer = 0;
            detData[vendIndex].RefTax = "";
            detData[vendIndex].IsSelect = false;

            if (isOpenTrans === false)
            {
                detData[vendIndex].dirty = true;
            }
        }
        else
        {
            detData.push({
                TransDetKey: 0,
                TransHdrKey: 0,
                AccNum: bcData.BankAcc,
                AccKey: bcData.BankAccKey,
                AccDesc: bcData.BankDesc,
                ClientKey: bcData.ClientKey,
                CurrKey: headerData.CurrKey,
                CurrCode: headerData.CurrCode,
                OUKey: headerData.OUKey,
                OUCode: headerData.OUCode,
                Remarks: headerData.Remarks,
                FuncTransAmt: 0,
                LocalTransAmt: 0,
                OrigTransAmt: vendAmt.Nnegated2(),
                OrigDrAmt: (vendAmt.Nnegated2() > 0 ? vendAmt.Nnegated2() : 0),
                OrigCrAmt: (vendAmt.Nnegated2() < 0 ? vendAmt.Nnegated2().Nabs() : 0),
                SeqNo: 0,
                ExRateFunc: headerData.ExRateToFunc,
                ExRateLocal: headerData.ExRateToLocal,
                CCID: bcData.BankCode,
                CCIDKey: bcData.BankCCIDKey,
                Qty: 0,
                UOMKey: null,
                UOM: "",
                UnitPrice: 0,
                Discount: 0,
                DiscountAmt: 0,
                Ind: "B",
                RefTransDetKey: null,
                RefTransHdrKey: null,
                APARRefTransHdrKey: null,
                APARRefTransDetKey: null,
                AdjAccKey: -1,
                AdjCCIDKey: -1,
                AdjAmount: 0,
                TaxPer: 0,
                RefTax: "",
                IsSelect: false,

                dirty: true
            });
        }
    };

    let _updateCashInfo = function (headerData, bcData, detData, convertType, isOpenTrans)
    {
        let vendAmt = 0;
        let vendExist = false;
        let vendIndex = -1;

        angular.forEach(detData, function (data, index)
        {
            if (data.Ind == "M")
            {
                vendExist = true;
                vendIndex = index;
            }
        });

        angular.forEach(detData, function (vdata, index)
        {
            //add RA into calculating the total vendor amount as well since in control service, ra was excluded by KY 06/11/2017
            if (vdata.Ind != "M" && vdata.Ind != "VR" && vdata.Ind != "RA" && !vdata.Ind.startsWith("T"))
            {
                if (vdata.CurrKey == headerData.CurrKey)
                {
                    vendAmt = vendAmt.Nadd2(vdata.OrigTransAmt);
                }
                else if (headerData.MultiCurrency == true && vdata.CurrKey == headerData.ForeignCurrKey)
                {
                    //for total payment we always let user decide the ratio and then convert the value using the ratio given
                    let convertRate = (headerData.ForeignExRate).Ndiv(headerData.ForeignExRateLocal);

                    vendAmt = vendAmt.Nadd2(vdata.OrigTransAmt.Ndiv(convertRate));
                }
            }

            if (vdata.Ind == "RA")  //add in T line to merge T line with V line by Cianshu 15/12/2016
            {
                if (headerData.EnableRoundingAdj == true)
                {
                    vendAmt = vendAmt.Nadd2(vdata.OrigTransAmt);
                }
            }

            if (vdata.Ind.startsWith("T"))
            {
                //Since tax amount is in MYR, we need to flip it to other currency judging from the opposite of company convert type
                if (convertType == "/")
                {
                    vendAmt = vendAmt.Nadd2(vdata.OrigTransAmt.Ntimes(headerData.ExRateToFunc));
                }
                else if (convertType == "*")
                {
                    vendAmt = vendAmt.Nadd2(vdata.OrigTransAmt.Ndiv(headerData.ExRateToFunc));
                }
            }

            if (bcData.PaymentCategory == "F")
            {
                vendAmt = vendAmt.Ntimes2(bcData.BuyingExRateFC);
            }
        });

        if (vendExist)
        {
            detData[vendIndex].AccNum = bcData.CashAcc;
            detData[vendIndex].AccKey = bcData.CashAccKey;
            detData[vendIndex].AccDesc = bcData.CashDesc;
            detData[vendIndex].ClientKey = bcData.ClientKey;
            detData[vendIndex].CurrKey = headerData.CurrKey;
            detData[vendIndex].CurrCode = headerData.CurrCode;
            detData[vendIndex].OUKey = headerData.OUKey;
            detData[vendIndex].OUCode = headerData.OUCode;
            detData[vendIndex].Remarks = headerData.Remarks;
            detData[vendIndex].FuncTransAmt = 0;
            detData[vendIndex].LocalTransAmt = 0;
            detData[vendIndex].OrigTransAmt = vendAmt.Nnegated2();
            detData[vendIndex].OrigDrAmt = (vendAmt.Nnegated2() > 0 ? vendAmt.Nnegated2() : 0);
            detData[vendIndex].OrigCrAmt = (vendAmt.Nnegated2() < 0 ? vendAmt.Nnegated2().Nabs() : 0);
            detData[vendIndex].SeqNo = 0;
            detData[vendIndex].ExRateFunc = headerData.ExRateToFunc;
            detData[vendIndex].ExRateLocal = headerData.ExRateToLocal;
            detData[vendIndex].CCID = bcData.CashCode;
            detData[vendIndex].CCIDKey = bcData.CashCCIDKey;
            detData[vendIndex].Qty = 0;
            detData[vendIndex].UOMKey = null;
            detData[vendIndex].UOM = "";
            detData[vendIndex].UnitPrice = 0;
            detData[vendIndex].Discount = 0;
            detData[vendIndex].DiscountAmt = 0;
            detData[vendIndex].Ind = "M";
            //detData[vendIndex].RefTransDetKey = null;
            //detData[vendIndex].RefTransHdrKey = null;
            //detData[vendIndex].APARRefTransHdrKey = null;
            //detData[vendIndex].APARRefTransDetKey = null;
            //detData[vendIndex].AdjAccKey = -1;
            //detData[vendIndex].AdjCCIDKey = -1;
            //detData[vendIndex].AdjAmount = 0;
            detData[vendIndex].TaxPer = 0;
            detData[vendIndex].RefTax = "";
            detData[vendIndex].IsSelect = false;

            if (isOpenTrans === false)
            {
                detData[vendIndex].dirty = true;
            }
        }
        else
        {
            detData.push({
                TransDetKey: 0,
                TransHdrKey: 0,
                AccNum: bcData.CashAcc,
                AccKey: bcData.CashAccKey,
                AccDesc: bcData.CashDesc,
                ClientKey: bcData.ClientKey,
                CurrKey: headerData.CurrKey,
                CurrCode: headerData.CurrCode,
                OUKey: headerData.OUKey,
                OUCode: headerData.OUCode,
                Remarks: headerData.Remarks,
                FuncTransAmt: 0,
                LocalTransAmt: 0,
                OrigTransAmt: vendAmt.Nnegated2(),
                OrigDrAmt: (vendAmt.Nnegated2() > 0 ? vendAmt.Nnegated2() : 0),
                OrigCrAmt: (vendAmt.Nnegated2() < 0 ? vendAmt.Nnegated2().Nabs() : 0),
                SeqNo: 0,
                ExRateFunc: headerData.ExRateToFunc,
                ExRateLocal: headerData.ExRateToLocal,
                CCID: bcData.CashCode,
                CCIDKey: bcData.CashCCIDKey,
                Qty: 0,
                UOMKey: null,
                UOM: "",
                UnitPrice: 0,
                Discount: 0,
                DiscountAmt: 0,
                Ind: "M",
                RefTransDetKey: null,
                RefTransHdrKey: null,
                APARRefTransHdrKey: null,
                APARRefTransDetKey: null,
                AdjAccKey: -1,
                AdjCCIDKey: -1,
                AdjAmount: 0,
                TaxPer: 0,
                RefTax: "",
                IsSelect: false,

                dirty: true
            });
        }
    };

    let _checkFutureDate = function (parseDate)
    {
        let startMth = new Date(new Date().getFullYear(), new Date().getMonth());
        let endMth = new Date(new Date().getFullYear(), new Date().getMonth() + 1);

        if (parseDate <= new Date())
        {
            return true;
        }
        else
        {
            if (startMth <= parseDate && parseDate < endMth)
            {
                return true;
            }
            else
            {
                return false;
            }
        }
    };

    let _checkDirtyflag = function (alldata)
    {
        let dirtyFlag = false;

        angular.forEach(alldata, function (data, index)
        {
            if (data.dirty == true)
            {
                dirtyFlag = true;
            }
        });

        if (dirtyFlag == true)
        {
            angular.forEach(alldata, function (data, index)
            {
                data.dirty = true;
            });
        }

        return dirtyFlag;
    };

    let _updateCurrInfo = function (alldata, currCode, currKey)
    {
        angular.forEach(alldata, function (data, index)
        {
            if (!data.Ind.startsWith("T") && data.Ind != "I" && data.Ind != "R1" && data.Ind != "R2")
            {
                data.CurrCode = currCode;
                data.CurrKey = currKey;
            }
        });
    };

    let _submitGL = function (isSaveAndSubmit, TransHdrKey, Status, additionalParam, InterCompTransHdrKey)
    {
        if (additionalParam === undefined || additionalParam === null)
        {
            additionalParam = "";
        }

        if (InterCompTransHdrKey === undefined || InterCompTransHdrKey === null) {
            InterCompTransHdrKey = -1;
        }       
    
        let defer = $q.defer();

        if (isSaveAndSubmit)
        {       
            $http({
                url: $rootScope.systemWebApiurl + 'Approval/BulkDocApproval?Module=' + (InterCompTransHdrKey <= 0 ? "FIN" : "DT"),
                method: "POST",
                data: [{
                    Key: InterCompTransHdrKey <= 0 ? TransHdrKey : InterCompTransHdrKey,
                    Reason: "",
                    Status: Status,
                    Action:"Submit"
                }]
            }).success(function (data) {
                if (data == '""') {
                    defer.resolve({
                        content: 'Document had been submitted for approval',
                        Status: "SM"
                    });
                }
                else {
                    defer.resolve(true);
                }
            }).error(function (data, status, headers, config) {
                defer.reject({
                    data: data,
                    status: status            
                });
            });
        }
        else
        {
            defer.resolve(false);
        }

        return defer.promise;
    };

    let _withdrawGL = function (TransHdrKey, Status, InterCompTransHdrKey)
    {
        let defer = $q.defer();

        if (InterCompTransHdrKey === undefined || InterCompTransHdrKey === null) {
            InterCompTransHdrKey = -1;
        }       

        $http({
            url: $rootScope.systemWebApiurl + 'Approval/BulkDocApproval?Module=' + (InterCompTransHdrKey <= 0 ? "FIN" : "DT"),
            method: "POST",
            data: [{
                Key: InterCompTransHdrKey <= 0 ? TransHdrKey : InterCompTransHdrKey,
                Reason: "",
                Status: Status,
                Action: "Withdraw"
            }]
        }).success(function (data) {
            if (data == '""') {
                defer.resolve({
                    content: 'Document has been withdrawn from approval',
                    Status: "OP",
                });
            }
            else {
                defer.resolve(true);
            }
        }).error(function (data, status, headers, config) {
            defer.reject({
                data: data,
                status: status
       
            });
        });

        return defer.promise;
    };

    let _retractGL = function (TransHdrKey, Status, Level)
    {
        let defer = $q.defer();
        var DocList = [];
        DocList.push({
            TransHdrKey: TransHdrKey,
            Status: Status,
            Level: Level
        });

        $http({
            url: $rootScope.systemWebApiurl + 'Approval/BulkDocApproval?Module=FIN',
            method: "POST",
            data: [{
                Key: TransHdrKey,
                Reason: "",
                Status: Status,
                Action: "SubmitRetract"
            }]
        }).success(function (data) {

            if (data == '""') {
                defer.resolve({
                    content: 'Document pending withdrawal approval',
                    Status: "OP",
                });
            }
            else {
       
                defer.reject({
                    data: JSON.parse(data),
                    status: status,
                    //headers: headers,
                    //config: config
                });              
            }
    
        }).error(function (data, status, headers, config) {
            defer.reject({
                data: data,
                status: status

            });
        });

        return defer.promise;
    };

    //shared datasource/option component
    let _gldetailDataSource = function (input)
    {
        let ds = new kendo.data.DataSource({
            type: "odata",
            transport:
            {
                read:
                {
                    url: input.read,
                    jsonp: false,
                    dataType: "json"
                }
            },
            schema:
            {
                data: input.data,
                total: function (data)
                {
                    return data["odata.count"];
                },
                model: {
                    id: "TransHdrKey",
                    fields:
                    {
                        TransDetKey: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        TransHdrKey: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        AccKey: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        AccNum: { type: "string", editable: true, nullable: false },
                        AccDesc: { type: "string", editable: false, nullable: false },
                        AccNumAccDesc: { type: "string", editable: true, nullable: false },
                        ClientKey: { type: "number", editable: true, nullable: false, defaultValue: -1 },
                        CCIDKey: { type: "number", editable: true, nullable: false, defaultValue: -1 },
                        OUKey: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        OUCode: { type: "string", editable: false, nullable: false },
                        Remarks: { type: "string", editable: true, nullable: false, validation: { maxlength: 250 } },
                        FuncTransAmt: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        LocalTransAmt: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        OrigTransAmt: { type: "number", editable: true, nullable: false, defaultValue: 0, validation: { required: true, min: 0, maxlength: 14 } },
                        OrigDrAmt: { type: "number", editable: true, nullable: false, defaultValue: 0, validation: { required: true, min: 0 } },
                        OrigCrAmt: { type: "number", editable: true, nullable: false, defaultValue: 0, validation: { required: true, min: 0 } },
                        CurrKey: { type: "number", editable: false, nullable: false },
                        CurrCode: { type: "string", editable: false, nullable: false },
                        SeqNo: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        ExRateFunc: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        ExRateLocal: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        CCID: { type: "string", editable: true, nullable: false },
                        Qty: { type: "number", editable: true, nullable: false, defaultValue: 0, validation: { required: true, min: 0, maxlength: 10 } },
                        UOMKey: { type: "number", editable: true, nullable: true, defaultValue: 0 },
                        UOM: { type: "string", editable: true, nullable: false },
                        UnitPrice: { type: "number", editable: true, nullable: false, defaultValue: 0, validation: { required: true, min: 0, maxlength: 14 } },
                        Discount: { type: "number", editable: true, nullable: false, defaultValue: 0, validation: { required: true, min: 0, max: 99.99 } },
                        DiscountTemp: { type: "number", editable: true, nullable: false, defaultValue: 0, validation: { required: true, min: 0, max: 99.99 } },
                        DiscountAmt: { type: "number", editable: true, nullable: false, defaultValue: 0, validation: { required: true, min: 0 } },
                        AOSTransAmt: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                        Ind: { type: "string", editable: true, nullable: false, defaultValue: "O" },
                        RefTransDetKey: { type: "number", editable: true, nullable: true, defaultValue: 0 },
                        RefTransHdrKey: { type: "number", editable: true, nullable: true, defaultValue: 0 },
                        TaxKey: { type: "number", editable: true, nullable: true, defaultValue: 0 },
                        TaxPer: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        RefTax: { type: "string", editable: true, nullable: false },
                        MvmtType: { type: "string", editable: false, nullable: false },
                        ItemCatCode: { type: "string", editable: false, nullable: false },
                        ItemID: { type: "string", editable: false, nullable: false },
                        JobCatCode: { type: "string", editable: false, nullable: false },
                        JobCode: { type: "string", editable: false, nullable: false },
                        DocDesc: { type: "string", editable: false, nullable: false },
                        DocNum: { type: "string", editable: false, nullable: false },
                        OpenAmt: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                        GrossAmt: { type: "number", editable: false, nullable: false, defaultValue: 0 },
                        AOSGLDate: { type: "date" },
                        AdjCCIDKey: { type: "number", editable: true, nullable: false, defaultValue: -1 },
                        AdjAccKey: { type: "number", editable: true, nullable: false, defaultValue: -1 },
                        AdjAccNumAccDesc: { type: "string", editable: true, nullable: false },
                        AdjCCIDCodeCCIDDesc: { type: "string", editable: true, nullable: false },
                        AdjAmount: { type: "number", editable: true, nullable: false, defaultValue: 0, validation: { min: 0, maxlength: 14 } },
                        IsSelect: { type: "boolean", editable: true, nullable: false, defaultValue: false },
                        rowid: { type: "string", editable: false, nullable: false },
                        IsOUAllocPosted: { type: "boolean", editable: true, nullable: false, defaultValue: false },
                        CCIDCodeCCIDDesc: { type: "string", editable: true, nullable: false },
                    }
                }
            },
            serverFiltering: true,
            serverPaging: true,
            serverSorting: true,
            sort:
                [
                    { field: "SeqNo", dir: "asc" },
                    { field: "TransDetKey", dir: "asc" }
                ],
            error: input.error,
            change: input.change
        });

        return ds;
    };

    let _glAccountingDataSource = function (input)
    {
        let ds = new kendo.data.DataSource({
            type: "odata",
            schema:
            {
                data: function (data)
                {
                    return broadcastService.parseStringToDecimal(data["value"]);
                },
                total: function (data)
                {
                    return data["odata.count"];
                },
                model:
                {
                    id: "TransHdrKey",
                    fields:
                    {
                        TransDetKey: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        TransHdrKey: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        AccKey: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        AccNum: { type: "string", editable: true, nullable: false },
                        AccDesc: { type: "string", editable: false, nullable: false },
                        ClientKey: { type: "number", editable: true, nullable: false, defaultValue: -1 },
                        OUKey: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        Remarks: { type: "string", editable: true, nullable: false, validation: { required: true, maxlength: 250 } },
                        FuncTransAmt: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        LocalTransAmt: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        OrigTransAmt: { type: "number", editable: true, nullable: false, defaultValue: 0, validation: { required: true, min: 0 } },
                        OrigDrAmt: { type: "number", editable: true, nullable: false, defaultValue: 0, validation: { required: true, min: 0 } },
                        OrigCrAmt: { type: "number", editable: true, nullable: false, defaultValue: 0, validation: { required: true, min: 0 } },
                        CurrCode: { type: "string", editable: true, nullable: false },
                        SeqNo: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        ExRateFunc: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        ExRateLocal: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        CCID: { type: "string", editable: true, nullable: false },
                        Qty: { type: "number", editable: true, nullable: false, defaultValue: 0, validation: { required: true, min: 0 } },
                        UOMKey: { type: "number", editable: true, nullable: true, defaultValue: 0 },
                        UOM: { type: "string", editable: true, nullable: false },
                        Ind: { type: "string", editable: true, nullable: false, defaultValue: "O" },
                        RefTransDetKey: { type: "number", editable: true, nullable: true, defaultValue: 0 },
                        RefTransHdrKey: { type: "number", editable: true, nullable: true, defaultValue: 0 },
                        AdjAccKey: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        AdjCCIDKey: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        AdjAmount: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        TaxPer: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        RefTax: { type: "string", editable: true, nullable: false },
                        CCIDCodeCCIDDesc: { type: "string", editable: true, nullable: false },
                    }
                }
            },
            serverFiltering: true,
            serverSorting: true,
            sort:
                [
                    { field: "SeqNo", dir: "asc" },
                    { field: "TransDetKey", dir: "asc" }
                ],
            error: input.error,
            change: input.change
        });

        return ds;
    };

    let _ouAllocDetailDataSource = function (input)
    {
        let ds = new kendo.data.DataSource({
            type: "odata",
            transport:
            {
                read:
                {
                    url: input.read,
                    jsonp: false,
                    dataType: "json",
                    complete: input.complete
                }
            },
            schema:
            {
                data: function (data)
                {
                    return broadcastService.parseStringToDecimal(data["value"]);
                },
                total: function (data)
                {
                    return data["odata.count"];
                },
                model:
                {
                    id: "OUAllocKey",
                    fields:
                    {
                        TransDetKey: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        OUAllocKey: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        AccKey: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        AccNum: { type: "string", editable: true, nullable: false },
                        AccDesc: { type: "string", editable: false, nullable: false },
                        AccNumAccDesc: { type: "string", editable: true, nullable: false },
                        ClientKey: { type: "number", editable: true, nullable: false, defaultValue: -1 },
                        CCIDKey: { type: "number", editable: true, nullable: false, defaultValue: -1 },
                        OUKey: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        OUCode: { type: "string", editable: false, nullable: false },
                        Remarks: { type: "string", editable: true, nullable: false, validation: { maxlength: 250 } },
                        FuncTransAmt: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        LocalTransAmt: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        OrigTransAmt: { type: "number", editable: true, nullable: false, defaultValue: 0, validation: { required: true, min: 0, maxlength: 14 } },
                        CurrCode: { type: "string", editable: false, nullable: false },
                        SeqNo: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        ExRateFunc: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        ExRateLocal: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        CCID: { type: "string", editable: true, nullable: false },
                        CCIDCodeCCIDDesc: { type: "string", editable: true, nullable: false, defaultValue: "" },
                        Ind: { type: "string", editable: true, nullable: false, defaultValue: "O" },
                        IsSelect: { type: "boolean", editable: true, nullable: false, defaultValue: false },
                        rowid: { type: "string", editable: false, nullable: false },
                        Qty: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        UnitPrice: { type: "number", editable: true, nullable: false, defaultValue: 0 },
                        UOM: { type: "string", editable: true, nullable: false },
                        UOMKey: { type: "number", editable: true, nullable: false, defaultValue: -1 },
                    }
                }
            },
            serverFiltering: true,
            serverPaging: true,
            serverSorting: true,
            sort:
                [
                    { field: "SeqNo", dir: "asc" },
                    { field: "TransDetKey", dir: "asc" }
                ],
            aggregate:
                [
                    { field: "Qty", aggregate: "sum" },
                    { field: "OrigTransAmt", aggregate: "sum" }
                ],
            error: input.error,
            change: input.change
        });

        return ds;
    };

    let _ouAllocDataSource = function (input)
    {
        let ds = new kendo.data.DataSource({
            type: "odata",
            transport: {
                read:
                {
                    url: input.read,
                    method: 'GET',
                    jsonp: false,
                    dataType: "json"
                },
            },
            schema: {
                data: function (data)
                {
                    return broadcastService.parseStringToDecimal(data["value"]);
                },
                total: function (data)
                {
                    return data["odata.count"];
                },
                model: {
                    id: "OUKey",
                    fields: {
                        OUKey: { type: "int", editable: false, nullable: false, defaultValue: 0 },
                        OUCode: { type: "string", editable: true, nullable: false },
                        OUDesc: { type: "string", editable: true, nullable: false },
                        OUCodeOUDesc: { type: "string", editable: true, nullable: false },
                    }
                }
            },
            serverFiltering: false,
            serverPaging: false,
            serverSorting: false,
            error: input.error
        });

        return ds;
    };

    let _TaxFunction = function (form, headerData, detgrid, transType, isSingleCurr, taxVendorData)
    {
        return new Promise((resolve, reject) =>
        {
            //transType => purchase or sales
            //detgrid => gldetailgrid, NOT datasource

            let langKey = $rootScope.userSetting.langKey;
            let detData = detgrid.dataSource.data();

            let gotSelect = false;
            let bseq = 0;
            let selectedList = [];
            let SelectedItem = [];

            let result = {
                isValid: true
            };

            angular.forEach(detData, function (selectedItem, index)
            {
                if (selectedItem.IsSelect === true)
                {
                    gotSelect = true;
                    selectedItem.Tax = "";
                    selectedList.push(selectedItem);
                }
            });

            if (!gotSelect)
            {
                result.msgHeaderIcon = angular.copy($rootScope.MsgIcon.ERROR);
                result.msgHeader = (broadcastService.getMsg(25).MsgBody);
                result.isValid = false;

                resolve(result);
            }
            else
            {
                if (selectedList.length > 1)
                {
                    let differentSign = false;
                    let firstSign = Math.sign(selectedList[0].OrigTransAmt);
                    for (var j = 0; j < selectedList.length; j++)
                    {
                        if (firstSign != Math.sign(selectedList[j].OrigTransAmt)) {
                            differentSign = true;
                        }
                        for (var i = 0; i < detData.length; i++)
                        {
                            if (detData[i].Ind.startsWith("T") && selectedList[j].SeqNo == detData[i].SeqNo)
                            {
                                selectedList[j].Tax += "T" + detData[i].TaxKey + ",";
                            }
                        }
                    }

                    let firstTax = selectedList[0].Tax;
                    if (firstTax != "")
                    {
                        for (var i = 1; i < selectedList.length; i++)
                        {
                            if (firstTax != selectedList[i].Tax)
                            {
                                result.msgHeaderIcon = angular.copy($rootScope.MsgIcon.ERROR);
                                result.msgHeader = (broadcastService.getMsg(334).MsgBody);
                                result.isValid = false;
                                break;
                            }
                        }
                    }

                    if (differentSign) {
                        result.msgHeaderIcon = angular.copy($rootScope.MsgIcon.ERROR);
                        result.msgHeader = (broadcastService.getMsg(639).MsgBody);
                        result.isValid = false;
                    }
                }

                if (result.isValid)
                {
                    let isValidVendInfo = true;
                    let vendInfoList = [];

                    if (form == "CP" || form == "BP" || form == "CR" || form == "BR")
                    {
                        angular.forEach(detData, function (selectedItem, index)
                        {
                            if (selectedItem.IsSelect)
                            {
                                let GSTID = selectedItem.VendorGSTID == undefined ? "" : selectedItem.VendorGSTID;
                                let GSTNM = selectedItem.VendorGSTName == undefined ? "" : selectedItem.VendorGSTName;
                                let GSTIN = selectedItem.InvNum == undefined ? "" : selectedItem.InvNum;
                                let tmpGSTInfo = GSTID + "|" + GSTNM + "|" + GSTIN;

                                if (!vendInfoList.includes(tmpGSTInfo))
                                {
                                    vendInfoList.push(tmpGSTInfo);
                                }
                            }
                        });
                    }

                    if (vendInfoList.length > 1)
                    {
                        isValidVendInfo = false;
                    }

                    angular.forEach(detData, function (selectedItem, index)
                    {
                        if (selectedItem.IsSelect)
                        {
                            if ((selectedItem.Ind.startsWith("T") || selectedItem.Ind == "V") && form != "CP" && form != "BP" && form != "CR" && form != "BR")
                            {
                                result.isValid = false;
                            }
                            else if ((selectedItem.Ind.startsWith("T") || selectedItem.Ind == "B" || selectedItem.Ind == "M") && (form == "CP" || form == "BP" || form == "CR" || form == "BR"))
                            {
                                result.isValid = false;
                            }
                            else if (selectedItem.AccNum == "")
                            {
                                result.msgHeaderIcon = angular.copy($rootScope.MsgIcon.ERROR);
                                result.msgHeader = "<strong>4.&nbsp;" + $rootScope.label['265'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $filter('stringFormat')($rootScope.msg['54'][langKey].MsgBody, [$rootScope.label['42'][langKey].LabelDesc]);
                                result.isValid = false;
                            }
                            else if (selectedItem.OrigTransAmt == 0 || selectedItem.OrigTransAmt == null)
                            {
                                result.msgHeaderIcon = angular.copy($rootScope.MsgIcon.ERROR);
                                result.msgHeader = "<strong>4.&nbsp;" + $rootScope.label['265'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $filter('stringFormat')($rootScope.msg['12'][langKey].MsgBody, [$rootScope.label['269'][langKey].LabelDesc + " " + $rootScope.label['921'][langKey].LabelDesc.toLowerCase() + " " + $rootScope.label['270'][langKey].LabelDesc]);
                                result.isValid = false;
                            }
                            else if (selectedItem.IsAdvPaymentPosted === true && (form == "CP" || form == "BP"))
                            {
                                result.msgHeaderIcon = angular.copy($rootScope.MsgIcon.ERROR);
                                result.msgHeader = "<strong>4.&nbsp;" + $rootScope.label['265'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $filter('stringFormat')($rootScope.msg['529'][langKey].MsgBody, [$rootScope.label['2498'][langKey].LabelDesc]);
                                result.isValid = false;
                            }
                            else if (!isValidVendInfo && (form == "CP" || form == "BP" || form == "CR" || form == "BR"))
                            {
                                result.msgHeaderIcon = angular.copy($rootScope.MsgIcon.ERROR);
                                result.msgHeader = "<strong>4.&nbsp;" + $rootScope.label['265'][langKey].LabelDesc + "</strong>:&nbsp;&nbsp;" + $filter('stringFormat')($rootScope.msg['332'][langKey].MsgBody);
                                result.isValid = false;
                            }
                            else
                            {
                                bseq = selectedItem.SeqNo;
                                SelectedItem = selectedItem;
                            }
                        }
                    });

                    if (result.isValid)
                    {
                        let taxDetailsDataSource = [];
                        let alldataDataSource = [];
                        let GotTax = false;
                        let taxList = [];

                        angular.forEach(detData, function (data, index)
                        {
                            data.OrigTransAmt = data.OrigTransAmt.NtoDP(2);
                            data.FuncTransAmt = data.FuncTransAmt.NtoDP(2);

                            if (data.IsSelect)
                            {
                                bseq = data.SeqNo;
                            }

                            if (data.Ind.startsWith("T") && data.SeqNo == bseq)
                            {
                                GotTax = true;

                                let taxFound = false;

                                for (var i = 0; i < taxList.length; i++)
                                {
                                    if (taxList[i].TaxKey == data.TaxKey)
                                    {
                                        taxFound = true;
                                        break;
                                    }
                                }

                                if (!taxFound)
                                {
                                    taxList.push({
                                        CCID: data.CCID,
                                        CCIDKey: data.CCIDKey,
                                        CCIDCodeCCIDDesc: data.CCIDCodeCCIDDesc,
                                        TaxCode: data.TaxCode,
                                        TaxKey: data.TaxKey,
                                        Ind: data.Ind,
                                        AccKey: data.AccKey,
                                        AccNum: data.AccNum,
                                        AccNumAccDesc: data.AccNumAccDesc,
                                        AccDesc: data.AccDesc,
                                        Remarks: data.Remarks,
                                        ExRateFunc: data.ExRateFunc,
                                        ExRateLocal: data.ExRateLocal,
                                        TaxPer: data.TaxPer,
                                        OrigTransAmt: 0,
                                        LocalTransAmt: 0,
                                        FuncTransAmt: 0,
                                        RefTax: data.RefTax,
                                        CurrKey: data.CurrKey,
                                        CurrCode: data.CurrCode,
                                        SeqNo: data.SeqNo,
                                        ClientKey: data.ClientKey,
                                        OUKey: data.OUKey,
                                        OUCode: data.OUCode,
                                        Qty: data.Qty,
                                        UnitPrice: data.UnitPrice,
                                        IsInclusive: data.IsInclusive,
                                        UOMKey: data.UOMKey,
                                    });
                                }
                            }
                        });

                        for (var i = 0; i < taxList.length; i++)
                        {
                            for (var j = 0; j < detData.length; j++)
                            {
                                if (detData[j].IsSelect)
                                {
                                    bseq = detData[j].SeqNo;
                                }

                                if (detData[j].TaxKey == taxList[i].TaxKey && detData[j].Ind.startsWith("T") && detData[j].SeqNo == bseq)
                                {
                                    taxList[i].OrigTransAmt = taxList[i].OrigTransAmt.Nadd2(detData[j].OrigTransAmt);
                                    taxList[i].FuncTransAmt = taxList[i].FuncTransAmt.Nadd2(detData[j].FuncTransAmt);
                                    taxList[i].LocalTransAmt = taxList[i].LocalTransAmt.Nadd2(detData[j].LocalTransAmt);
                                }
                            }

                            taxDetailsDataSource.push(taxList[i]);
                        }

                        /*
                        for (var ik = 0; ik < taxDetailsDataSource.length; ik++)
                        {
                            if (taxDetailsDataSource[ik].OrigTransAmt < 0)
                            {
                                taxDetailsDataSource[ik].OrigTransAmt = taxDetailsDataSource[ik].OrigTransAmt.Nabs();
                                taxDetailsDataSource[ik].FuncTransAmt = taxDetailsDataSource[ik].FuncTransAmt.Nabs();
                                taxDetailsDataSource[ik].LocalTransAmt = taxDetailsDataSource[ik].LocalTransAmt.Nabs();
                            }
                        }*/

                        let totalSelectedOrigTransAmt = 0, totalSelectedInclusiveTransAmt = 0;
                        angular.forEach(detData, function (data, index)
                        {
                            if (data.IsSelect)
                            {
                                if (form == "CP" || form == "BP" || form == "CR" || form == "BR")
                                {
                                    if (data.Ind == 'O' || data.Ind == 'V')
                                    {
                                        alldataDataSource.push(data);
                                        totalSelectedInclusiveTransAmt = totalSelectedInclusiveTransAmt.Nadd2(data.InclusiveTransAmt);
                                        totalSelectedOrigTransAmt = totalSelectedOrigTransAmt.Nadd2(data.OrigTransAmt);
                                    }
                                }
                                else
                                {
                                    //need to handle I line in DN as well
                                    if (data.Ind == 'O' || data.Ind == 'I')
                                    {
                                        alldataDataSource.push(data);
                                        totalSelectedInclusiveTransAmt = totalSelectedInclusiveTransAmt.Nadd2(data.InclusiveTransAmt);
                                        totalSelectedOrigTransAmt = totalSelectedOrigTransAmt.Nadd2(data.OrigTransAmt);
                                    }
                                }
                            }
                        });

                        $ocLazyLoad.load({
                            name: "enterGJTax",
                            files: ["finance/controllers/enterGJTaxController.js"]
                        }).then(function ()
                        {
                            let modalInstance = $modal.open({
                                backdrop: 'static',
                                keyboard: false,
                                templateUrl: '/finance/views/enterGJTax.html',
                                controller: 'enterGJTaxController',
                                resolve:
                                {
                                    aValue: function ()
                                    {
                                        if (form == "CP" || form == "BP" || form == "CR" || form == "BR")
                                        {
                                            return {
                                                GLDate: kendo.toString(headerData.GLDate, 'd'),
                                                OrigTransAmt: totalSelectedOrigTransAmt,
                                                BaseExRate: headerData.ExRateToFunc,
                                                BaseCurrKey: headerData.CurrKey,
                                                BaseAccKey: SelectedItem.AccKey,
                                                BaseAccNum: SelectedItem.AccNum,
                                                BaseAccDesc: SelectedItem.AccDesc,
                                                BaseSeqNo: SelectedItem.SeqNo,
                                                BaseClientKey: headerData.ClientKey,
                                                BaseOUKey: headerData.OUKey,
                                                ParaMode: transType,
                                                singleCurrFlag: isSingleCurr,
                                                taxDS: taxDetailsDataSource,
                                                allDS: alldataDataSource,
                                                winTaxFormVisible: true,
                                                GotTax: GotTax,
                                                BaseRemark: SelectedItem.Remarks,
                                                ParentForm: form,
                                                InclusiveTransAmt: totalSelectedInclusiveTransAmt,
                                                ShowIsTaxInclusive: true,
                                                IsInclusive: SelectedItem.IsInclusive,

                                                Status: headerData.Status,
                                                VendorGSTID: selectedList[0].VendorGSTID,
                                                VendorGSTName: selectedList[0].VendorGSTName,
                                                InvNum: selectedList[0].InvNum,
                                                InvoiceDate: new Date(selectedList[0].InvoiceDate),
                                                allowZeroRatedTaxOnly: false
                                            };
                                        }
                                        else
                                        {
                                            return {
                                                GLDate: kendo.toString(headerData.GLDate, 'd'),
                                                OrigTransAmt: totalSelectedOrigTransAmt,
                                                BaseExRate: headerData.ExRateToFunc,
                                                BaseCurrKey: headerData.CurrKey,
                                                BaseAccKey: SelectedItem.AccKey,
                                                BaseAccNum: SelectedItem.AccNum,
                                                BaseAccDesc: SelectedItem.AccDesc,
                                                BaseSeqNo: SelectedItem.SeqNo,
                                                BaseClientKey: headerData.ClientKey,
                                                BaseOUKey: headerData.OUKey,
                                                ParaMode: transType,
                                                singleCurrFlag: isSingleCurr,
                                                taxDS: taxDetailsDataSource,
                                                allDS: alldataDataSource,
                                                winTaxFormVisible: true,
                                                GotTax: GotTax,
                                                BaseRemark: SelectedItem.Remarks,
                                                ParentForm: form,
                                                InclusiveTransAmt: totalSelectedInclusiveTransAmt,
                                                ShowIsTaxInclusive: true,
                                                IsInclusive: SelectedItem.IsInclusive,
                                            };
                                        }
                                    }
                                }
                            });

                            modalInstance.result.then(function (returnData)
                            {
                                if ((form == "CP" || form == "BP" || form == "CR" || form == "BR") && taxVendorData !== undefined)
                                {
                                    /*******************************************Insert tax vendor part*************************************************/
                                    //temporarily variable to store the existing vendor data
                                    //Since the modal pop out did not save the data immediately, hence when the data is return upon calling the modal popout,
                                    //we will need to save the data in a temporarily data source
                                    //Once open, we will use the unique identifier to compare and assign the new value
                                    //TransDetKey and rowid is used seperately judging from the condition

                                    let gstVendorItem = returnData[1];
                                    let temptaxVendorData = taxVendorData.customClone(false, false);

                                    for (var i = 0; i < gstVendorItem.length; i++)
                                    {
                                        //Assign VendorGSTID to each selected detail line
                                        for (var j = 0; j < detData.length; j++)
                                        {
                                            if ((gstVendorItem[i].rowid != "" && gstVendorItem[i].rowid != null && gstVendorItem[i].rowid == detData[j].rowid) || (detData[j].TransDetKey > 0 && detData[j].TransDetKey == gstVendorItem[i].TransDetKey))
                                            {
                                                detData[j].VendorGSTID = gstVendorItem[i].VendorGSTID;
                                                detData[j].VendorGSTName = gstVendorItem[i].VendorGSTName;
                                                detData[j].InvNum = gstVendorItem[i].InvNum;
                                                detData[j].InvoiceDate = new Date(gstVendorItem[i].InvoiceDate);
                                            }
                                        }
                                    }

                                    //obtain the data from modal pop out for any changes and then assign the data properly to temp variable b4 assign it back
                                    if (temptaxVendorData._data.length > 0)
                                    {
                                        for (var i = 0; i < gstVendorItem.length; i++)
                                        {
                                            var foundFlag = false;

                                            for (var j = 0; j < temptaxVendorData._data.length; j++)
                                            {
                                                //this is for normal transaction where TransDetKey is not empty
                                                if ((gstVendorItem[i].TransDetKey > 0 && temptaxVendorData._data[j].TransDetKey == gstVendorItem[i].TransDetKey) || (gstVendorItem[i].rowid != "" && gstVendorItem[i].rowid != null && temptaxVendorData._data[j].rowid == gstVendorItem[i].rowid))
                                                {
                                                    foundFlag = true;
                                                    temptaxVendorData._data[j].FIN_TransDetGSTKey = gstVendorItem[i].FIN_TransDetGSTKey;
                                                    temptaxVendorData._data[j].VendorGSTID = gstVendorItem[i].VendorGSTID;
                                                    temptaxVendorData._data[j].VendorGSTName = gstVendorItem[i].VendorGSTName;
                                                    temptaxVendorData._data[j].InvoiceDate = new Date(gstVendorItem[i].InvoiceDate);
                                                    temptaxVendorData._data[j].InvNum = gstVendorItem[i].InvNum;
                                                }
                                            }
                                            if (!foundFlag)
                                            {
                                                gstVendorItem[i].FIN_TransDetGSTKey = -1;
                                                temptaxVendorData._data.push(gstVendorItem[i]);
                                            }
                                        }
                                    }

                                    if (temptaxVendorData._data.length > 0)
                                    {
                                        taxVendorData.data(temptaxVendorData._data.toJSON());
                                    }
                                    else
                                    {
                                        for (var i = 0; i < gstVendorItem.length; i++)
                                        {
                                            gstVendorItem[i].FIN_TransDetGSTKey = -1;
                                            taxVendorData._data.push(gstVendorItem[i]);
                                        }
                                    }
                                }
                             
                                //will always delete all of the tax line before inserting new tax line --- remark by Kee Yong 17/05/2017
                                for (var i = 0; i < detData.length; i++)
                                {
                                    if (detData[i].IsSelect)
                                    {
                                        // delete existing tax & creditor line

                                        let uidlist = "";
                                        let detNo = 0;

                                        angular.forEach(detData, function (data, index)
                                        {
                                            if ((data.SeqNo == detData[i].SeqNo && data.Ind.startsWith("T")) || (data.Ind == "TC"))
                                            {
                                                detNo = detNo + 1;
                                                if (uidlist == "")
                                                {
                                                    uidlist = data.uid;
                                                }
                                                else
                                                {
                                                    uidlist = uidlist + "," + data.uid;
                                                }
                                            }
                                        });

                                        // delete tax line
                                        if (detNo != 0)
                                        {
                                            let arr = uidlist.split(",");
                                            for (var ix = 0; ix < detNo; ix++)
                                            {
                                                detgrid.dataSource.remove(detgrid.dataSource.getByUid(arr[ix]));
                                            }
                                        }                                      
                                    }
                                }

                                let bIndex = -1;
                                let isPossitive = false;

                                if (form == "CP" || form == "BP" || form == "CR" || form == "BR")
                                {
                                    angular.forEach(returnData[0], function (data, index)
                                    {
                                        let lineAmt = 0;
                                        for (var i = 0; i < detData.length; i++)
                                        {
                                            if (detData[i].SeqNo == data.SeqNo)
                                            {
                                                lineAmt = detData[i].OrigTransAmt;
                                                //checking the nature of the base line on whether it is negative/possitve
                                                if (detData[i].OrigTransAmt > 0)
                                                {
                                                    isPossitive = true;
                                                }
                                                else
                                                {
                                                    isPossitive = false;
                                                }
                                                bIndex = i;
                                                break;
                                            }
                                        }

                                        let totalAmt = totalSelectedOrigTransAmt;
                                        data.OrigTransAmt = lineAmt.Ndiv(totalAmt).Ntimes2(data.OrigTransAmt);


                                        //here always convert all data for the tax datasource to absolute
                                        //then based on the base line, re-assign again
                                        /*
                                         * if (data.RefTax != 'W')
                                        {
                                            if (isPossitive)
                                            {
                                                data.OrigTransAmt = data.OrigTransAmt.NtoDP(2).Nabs();
                                                data.LocalTransAmt = data.LocalTransAmt.NtoDP(2).Nabs();
                                                data.FuncTransAmt = data.FuncTransAmt.NtoDP(2).Nabs();
                                            }
                                            else
                                            {
                                                data.OrigTransAmt = data.OrigTransAmt.NtoDP(2).Nabs().Nnegated2();
                                                data.LocalTransAmt = data.LocalTransAmt.NtoDP(2).Nabs().Nnegated2();
                                                data.FuncTransAmt = data.FuncTransAmt.NtoDP(2).Nabs().Nnegated2();
                                            }
                                        }*/

                                        if (data.OrigTransAmt > 0)
                                        {
                                            data.OrigCrAmt = 0;
                                            data.OrigDrAmt = data.OrigTransAmt.Nabs();
                                        }
                                        else
                                        {
                                            data.OrigCrAmt = data.OrigTransAmt.Nabs();
                                            data.OrigDrAmt = 0;
                                        }

                                        if (data.Ind.startsWith("T"))
                                        {
                                            if (data.IsInclusive == 1)
                                            {
                                                detData[i].OrigTransAmt = detData[i].InclusiveTransAmt.Nadd2(data.OrigTransAmt.Nnegated2());
                                            }
                                            else
                                            {
                                                detData[i].OrigTransAmt = detData[i].InclusiveTransAmt.NtoDP(2);
                                            }

                                            if (detData[i].OrigTransAmt > 0)
                                            {
                                                detData[i].OrigCrAmt = 0;
                                                detData[i].OrigDrAmt = detData[i].OrigTransAmt.Nabs();
                                            }
                                            else
                                            {
                                                detData[i].OrigCrAmt = detData[i].OrigTransAmt.Nabs();
                                                detData[i].OrigDrAmt = 0;
                                            }

                                            detData[i].IsInclusive = data.IsInclusive;
                                        }

                                        detgrid.dataSource.insert((bIndex + 1), data);
                                    });

                                    //recalculate tax when remove tax from inclusive tax
                                    if (returnData[0].length == 0)
                                    {
                                        for (var i = 0; i < detData.length; i++)
                                        {
                                            if (detData[i].Ind != "B" && detData[i].Ind != "M" && !detData[i].Ind.startsWith("T"))
                                            {
                                                detData[i].OrigTransAmt = detData[i].InclusiveTransAmt;

                                                if (detData[i].OrigTransAmt > 0)
                                                {
                                                    detData[i].OrigCrAmt = 0;
                                                    detData[i].OrigDrAmt = detData[i].OrigTransAmt.Nabs();
                                                }
                                                else
                                                {
                                                    detData[i].OrigCrAmt = detData[i].OrigTransAmt.Nabs();
                                                    detData[i].OrigDrAmt = 0;
                                                }
                                            }
                                        }
                                    }
                                }
                                else
                                {

                                    angular.forEach(returnData, function (data, index)
                                    {
                                        for (var i = 0; i < detData.length; i++)
                                        {
                                            if (detData[i].SeqNo == data.SeqNo)
                                            {

                                                lineAmt = detData[i].OrigTransAmt;
                                                //checking the nature of the base line on whether it is negative/possitve
                                                if (detData[i].OrigTransAmt > 0)
                                                {
                                                    isPossitive = true;
                                                }
                                                else
                                                {
                                                    isPossitive = false;
                                                }
                                                bIndex = i;
                                                break;
                                            }
                                        }

                                        //here always convert all data for the tax datasource to absolute
                                        //then based on the base line, re-assign again
                                        /*
                                         * if (data.RefTax != 'W')
                                        {
                                            if (isPossitive)
                                            {
                                                data.OrigTransAmt = data.OrigTransAmt.NtoDP(2).Nabs();
                                                data.LocalTransAmt = data.LocalTransAmt.NtoDP(2).Nabs();
                                                data.FuncTransAmt = data.FuncTransAmt.NtoDP(2).Nabs();
                                            }
                                            else
                                            {
                                                data.OrigTransAmt = data.OrigTransAmt.NtoDP(2).Nabs().Nnegated2();
                                                data.LocalTransAmt = data.LocalTransAmt.NtoDP(2).Nabs().Nnegated2();
                                                data.FuncTransAmt = data.FuncTransAmt.NtoDP(2).Nabs().Nnegated2();
                                            }
                                        }
                                        else if (data.RefTax == 'W')
                                        {
                                            //the figure pass in is correct but is the wrong sign
                                            //so always need to assign the sign correctly again
                                            if (isPossitive)
                                            {
                                                data.OrigTransAmt = data.OrigTransAmt.NtoDP(2).Nabs().Nnegated2();
                                                data.LocalTransAmt = data.LocalTransAmt.NtoDP(2).Nabs().Nnegated2();
                                                data.FuncTransAmt = data.FuncTransAmt.NtoDP(2).Nabs().Nnegated2();
                                            }
                                            else
                                            {
                                                data.OrigTransAmt = data.OrigTransAmt.NtoDP(2).Nabs();
                                                data.LocalTransAmt = data.LocalTransAmt.NtoDP(2).Nabs();
                                                data.FuncTransAmt = data.FuncTransAmt.NtoDP(2).Nabs();
                                            }
                                        }*/

                                        let totalAmt = totalSelectedOrigTransAmt;
                                        data.OrigTransAmt = lineAmt.Ndiv(totalAmt).Ntimes2(data.OrigTransAmt);

                                        if (data.OrigTransAmt > 0)
                                        {
                                            data.OrigCrAmt = 0;
                                            data.OrigDrAmt = data.OrigTransAmt.Nabs();
                                        }
                                        else
                                        {
                                            data.OrigCrAmt = data.OrigTransAmt.Nabs();
                                            data.OrigDrAmt = 0;
                                        }

                                        if (data.Ind.startsWith("T"))
                                        {
                                            if (data.IsInclusive == 1)
                                            {
                                                detData[i].OrigTransAmt = detData[i].InclusiveTransAmt.Nadd2(data.OrigTransAmt.Nnegated2());
                                            }
                                            else
                                            {
                                                detData[i].OrigTransAmt = detData[i].InclusiveTransAmt.NtoDP(2);
                                            }

                                            if (detData[i].OrigTransAmt > 0)
                                            {
                                                detData[i].OrigCrAmt = 0;
                                                detData[i].OrigDrAmt = detData[i].OrigTransAmt.Nabs();
                                            }
                                            else
                                            {
                                                detData[i].OrigCrAmt = detData[i].OrigTransAmt.Nabs();
                                                detData[i].OrigDrAmt = 0;
                                            }

                                            detData[i].IsInclusive = data.IsInclusive;
                                        }

                                        detgrid.dataSource.insert((bIndex + 1), data);
                                    });

                                    //recalculate tax when remove tax from inclusive tax
                                    if (returnData.length == 0)
                                    {
                                        for (var i = 0; i < detData.length; i++)
                                        {
                                            if (detData[i].Ind != "V" && !detData[i].Ind.startsWith("T"))
                                            {
                                                detData[i].OrigTransAmt = detData[i].InclusiveTransAmt;

                                                if (detData[i].OrigTransAmt > 0)
                                                {
                                                    detData[i].OrigCrAmt = 0;
                                                    detData[i].OrigDrAmt = detData[i].OrigTransAmt.Nabs();
                                                }
                                                else
                                                {
                                                    detData[i].OrigCrAmt = detData[i].OrigTransAmt.Nabs();
                                                    detData[i].OrigDrAmt = 0;
                                                }
                                            }
                                        }
                                    }
                                }

                                resolve(result);
                            });
                        });
                    }
                    else
                    {
                        resolve(result);
                    }
                }
                else
                {
                    resolve(result);
                }
            }
        });
    };

    let _updateTaxFunc = function (form, headerData, detgrid, transType, isSingleCurr, bseq, Method, ConvertType)
    {
        return new Promise((resolve, reject) =>
        {
            let taxDetailsDataSource = [];
            let alldataDataSource = [];
            let GotTax = false;
            let taxList = [];

            let detData = detgrid.dataSource.data();

            let baseorigtransamt = 0;
            let baseexrate = 0;
            let baseremark = "";

            angular.forEach(detData, function (data, index)
            {
                if (form == "CP" || form == "BP" || form == "CR" || form == "BR")
                {
                    if ((data.Ind.startsWith("T") || data.Ind == "O" || data.Ind == "V") && data.SeqNo == bseq)
                    {
                        taxDetailsDataSource.push({
                            CCID: data.CCID,
                            CCIDKey: data.CCIDKey,
                            CCIDCodeCCIDDesc: data.CCIDCodeCCIDDesc,
                            TaxCode: data.TaxCode,
                            TaxKey: data.TaxKey,
                            Ind: data.Ind,
                            AccKey: data.AccKey,
                            AccNum: data.AccNum,
                            AccNumAccDesc: data.AccNumAccDesc,
                            AccDesc: data.AccDesc,
                            Remarks: data.Remarks,
                            ExRateFunc: data.ExRateFunc,
                            ExRateLocal: data.ExRateLocal,
                            TaxPer: data.TaxPer,
                            OrigTransAmt:  data.OrigTransAmt,
                            LocalTransAmt:  data.LocalTransAmt,
                            FuncTransAmt:  data.FuncTransAmt,
                            RefTax: data.RefTax,
                            CurrKey: data.CurrKey,
                            CurrCode: data.CurrCode,
                            SeqNo: data.SeqNo,
                            ClientKey: data.ClientKey,
                            OUKey: data.OUKey,
                            OUCode: data.OUCode,
                            Qty: data.Qty,
                            UnitPrice: data.UnitPrice,
                            IsInclusive: data.IsInclusive,
                            UOMKey: data.UOMKey,
                        });
                    }

                    if ((data.Ind == "O" || data.Ind == "V") && data.SeqNo == bseq)
                    {
                        if (data.ExRateFunc != 1)
                        {
                            if (ConvertType == "/")
                            {
                                baseorigtransamt = data.OrigTransAmt.Ndiv(data.ExRateFunc);
                            }
                            else
                            {
                                baseorigtransamt = data.OrigTransAmt.Ntimes(data.ExRateFunc);
                            }
                        }
                        else
                        {
                            baseorigtransamt = data.OrigTransAmt;
                        }

                        baseremark = data.Remarks;
                    }
                }
                else
                {
                    if ((data.Ind.startsWith("T") || data.Ind == "O") && data.SeqNo == bseq)
                    {
                        taxDetailsDataSource.push({
                            CCID: data.CCID,
                            CCIDKey: data.CCIDKey,
                            CCIDCodeCCIDDesc: data.CCIDCodeCCIDDesc,
                            TaxCode: data.TaxCode,
                            TaxKey: data.TaxKey,
                            Ind: data.Ind,
                            AccKey: data.AccKey,
                            AccNum: data.AccNum,
                            AccNumAccDesc: data.AccNumAccDesc,
                            AccDesc: data.AccDesc,
                            Remarks: data.Remarks,
                            ExRateFunc: data.ExRateFunc,
                            ExRateLocal: data.ExRateLocal,
                            TaxPer: data.TaxPer,
                            OrigTransAmt: data.OrigTransAmt,
                            LocalTransAmt: data.LocalTransAmt,
                            FuncTransAmt:  data.FuncTransAmt,
                            RefTax: data.RefTax,
                            CurrKey: data.CurrKey,
                            CurrCode: data.CurrCode,
                            SeqNo: data.SeqNo,
                            ClientKey: data.ClientKey,
                            OUKey: data.OUKey,
                            OUCode: data.OUCode,
                            Qty: data.Qty,
                            UnitPrice: data.UnitPrice,
                            IsInclusive: data.IsInclusive,
                            UOMKey: data.UOMKey,
                        });
                    }

                    if ((data.Ind == "O" || data.Ind == "I" || data.Ind == "CW") && data.SeqNo == bseq)
                    {
                        if (headerData.ExRateFunc != 1)
                        {
                            if (ConvertType == "*")
                            {
                                baseorigtransamt = data.OrigTransAmt.Ntimes(data.ExRateFunc);
                            }
                            else
                            {
                                baseorigtransamt = data.OrigTransAmt.Ndiv(data.ExRateFunc);
                            }
                        }
                        else
                        {
                            baseorigtransamt = data.OrigTransAmt;
                        }

                        baseremark = data.Remarks;
                    }
                }

                //Change to standard Tax ExRate all get from T or TN line itself
                if (data.Ind.startsWith("T") && data.SeqNo == bseq)
                {
                    baseexrate = data.ExRateLocal;
                }
            });

            angular.forEach(detData, function (data, index)
            {
                alldataDataSource.push(data);
            });

            $ocLazyLoad.load({
                name: "enterGJTax",
                files: ["finance/controllers/enterGJTaxController.js"]
            }).then(function ()
            {
                let modalInstance = $modal.open({
                    backdrop: 'static',
                    templateUrl: '/finance/views/enterGJTax.html',
                    controller: 'enterGJTaxController',
                    resolve:
                    {
                        aValue: function ()
                        {
                            if (form == "CP" || form == "BP" || form == "CR" || form == "BR")
                            {
                                return {
                                    GLDate: kendo.toString(headerData.GLDate, 'd'),
                                    OrigTransAmt: baseorigtransamt,
                                    BaseExRate: baseexrate,
                                    BaseSeqNo: bseq,
                                    BaseCurrKey: headerData.CurrKey,
                                    BaseClientKey: headerData.ClientKey,
                                    BaseOUKey: headerData.OUKey,
                                    BaseOUCode: headerData.OUCode,
                                    ParaMode: transType,
                                    singleCurrFlag: isSingleCurr,
                                    taxDS: taxDetailsDataSource,
                                    allDS: alldataDataSource,
                                    method: Method,
                                    winTaxFormVisible: false,
                                    GotTax: GotTax,
                                    // new requirement that tax remark should be compose of tax code and the base line remark by Kee Yong 16/05/2017
                                    BaseRemark: baseremark,
                                    ParentForm: form,

                                    allowZeroRatedTaxOnly: false
                                };
                            }
                            else
                            {
                                return {
                                    GLDate: kendo.toString(headerData.GLDate, 'd'),
                                    OrigTransAmt: baseorigtransamt,
                                    BaseExRate: baseexrate,
                                    BaseSeqNo: bseq,
                                    BaseCurrKey: headerData.CurrKey,
                                    BaseClientKey: headerData.ClientKey,
                                    BaseOUKey: headerData.OUKey,
                                    BaseOUCode: headerData.OUCode,
                                    ParaMode: transType,
                                    singleCurrFlag: isSingleCurr,
                                    taxDS: taxDetailsDataSource,
                                    allDS: alldataDataSource,
                                    method: Method,
                                    winTaxFormVisible: false,
                                    GotTax: GotTax,
                                    // new requirement that tax remark should be compose of tax code and the base line remark by Kee Yong 16/05/2017
                                    BaseRemark: baseremark,
                                    ParentForm: form,
                                };
                            }
                        }
                    }
                });

                modalInstance.result.then(function (returnData)
                {
                    let uidlist = "";
                    let detNo = 0;

                    angular.forEach(detData, function (data, index)
                    {
                        if ((data.SeqNo == bseq && data.Ind.startsWith("T")) || (data.Ind == "TC"))
                        {
                            detNo = detNo + 1;
                            if (uidlist == "")
                            {
                                uidlist = data.uid;
                            }
                            else
                            {
                                uidlist = uidlist + "," + data.uid;
                            }
                        }
                    });

                    // delete tax line
                    if (detNo != 0)
                    {
                        let arr = uidlist.split(",");
                        for (var ix = 0; ix < detNo; ix++)
                        {
                            detgrid.dataSource.remove(detgrid.dataSource.getByUid(arr[ix]));
                        }
                    }

                    let bIndex = -1;
                    let isPossitive = false;

                    angular.forEach(returnData, function (data, index)
                    {
                        for (var i = 0; i < detData.length; i++)
                        {
                            if (detData[i].SeqNo == bseq)
                            {
                                //checking the nature of the base line on whether it is negative/possitve
                                if (detData[i].OrigTransAmt > 0)
                                {
                                    isPossitive = true;
                                }
                                else
                                {
                                    isPossitive = false;
                                }
                                bIndex = i;
                                break;
                            }
                        }

                        //here always convert all data for the tax datasource to absolute
                        //then based on the base line, re-assign again
                        /*
                         * if (data.RefTax != "W")
                        {
                            if (isPossitive)
                            {
                                data.OrigTransAmt = data.OrigTransAmt.NtoDP(2).Nabs();
                                data.LocalTransAmt = data.LocalTransAmt.NtoDP(2).Nabs();
                                data.FuncTransAmt = data.FuncTransAmt.NtoDP(2).Nabs();
                            }
                            else
                            {
                                data.OrigTransAmt = data.OrigTransAmt.NtoDP(2).Nabs().Nnegated2();
                                data.LocalTransAmt = data.LocalTransAmt.NtoDP(2).Nabs().Nnegated2();
                                data.FuncTransAmt = data.FuncTransAmt.NtoDP(2).Nabs().Nnegated2();
                            }
                        }
                        else if (data.RefTax == 'W')
                        {
                            //the figure pass in is correct but is the wrong sign
                            //so always need to assign the sign correctly again
                            if (isPossitive)
                            {
                                data.OrigTransAmt = data.OrigTransAmt.NtoDP(2).Nabs().Nnegated2();
                                data.LocalTransAmt = data.LocalTransAmt.NtoDP(2).Nabs().Nnegated2();
                                data.FuncTransAmt = data.FuncTransAmt.NtoDP(2).Nabs().Nnegated2();
                            }
                            else
                            {
                                data.OrigTransAmt = data.OrigTransAmt.NtoDP(2).Nabs();
                                data.LocalTransAmt = data.LocalTransAmt.NtoDP(2).Nabs();
                                data.FuncTransAmt = data.FuncTransAmt.NtoDP(2).Nabs();
                            }
                        }*/

                        if (data.OrigTransAmt > 0)
                        {
                            data.OrigCrAmt = 0;
                            data.OrigDrAmt = data.OrigTransAmt.Nabs();
                        }
                        else
                        {
                            data.OrigCrAmt = data.OrigTransAmt.Nabs();
                            data.OrigDrAmt = 0;
                        }

                        if (data.Ind.startsWith("T"))
                        {
                            if (data.IsInclusive == 1)
                            {
                                detData[i].OrigTransAmt = detData[i].InclusiveTransAmt.Nadd2(data.OrigTransAmt.Nnegated2());
                            }
                            else
                            {
                                detData[i].OrigTransAmt = detData[i].InclusiveTransAmt.NtoDP(2);
                            }

                            if (detData[i].OrigTransAmt > 0)
                            {
                                detData[i].OrigCrAmt = 0;
                                detData[i].OrigDrAmt = detData[i].OrigTransAmt.Nabs();
                            }
                            else
                            {
                                detData[i].OrigCrAmt = detData[i].OrigTransAmt.Nabs();
                                detData[i].OrigDrAmt = 0;
                            }

                            detData[i].IsInclusive = data.IsInclusive;
                        }

                        detgrid.dataSource.insert((bIndex + 1), data);
                    });

                    resolve();
                });
            });
        });
    };

    let noOfTaxLine = function (data, SeqNo)
    {
        //find number of tax line for the base line
        let noOfTax = data.filter(function (item)
        {
            if (item.Ind.startsWith("T") && item.SeqNo == SeqNo)
            {
                return true;
            }
            else
            {
                return false;
            }
        });

        return noOfTax.length + 1;
    }

    let findBaseLine = function (data, SeqNo)
    {
        //find the original base line with original seqno
        let baseline = data.filter(function (item)
        {
            if (!item.Ind.startsWith("T") && item.SeqNo == SeqNo)
            {
                return true;
            }
            else
            {
                return false;
            }
        });

        if (baseline.length > 0)
        {
            return baseline[0].uid
        }
        else
        {
            return "";
        }
    }

    let findBaseLineSeqNo = function (uid, info, data)
    {
        //find the new base line seqno after changes are made
        let findUid = info.filter(function (item)
        {
            if (item.TaxUid == uid)
            {
                return true;
            }
            else
            {
                return false;
            }
        });

        var finalUid = "";
        if (findUid.length > 0)
        {
            let baseline = data.filter(function (item)
            {
                if (!item.Ind.startsWith("T") && item.uid == findUid[0].BaseLineUid)
                {
                    return true;
                }
                else
                {
                    return false;
                }
            });

            if (baseline.length > 0)
            {
                return baseline[0].SeqNo
            }
            else
            {
                return 0;
            }
        }
        else
        {
            return 0;
        }
    }

    let _addNewLine = function (grid, source, currCode, currKey)
    {
        try
        {
            let sel_idx = grid.select().index();
            let idx = grid.dataSource.indexOf(grid.dataItem(grid.select()));
            let Tlinefound = false;

            if (idx >= 0)
            {
                //check if next line is tax
                if (grid.dataSource.data()[idx + 1] !== undefined)
                {
                    if (grid.dataSource.data()[idx + 1].Ind.startsWith("T"))
                    {
                        //T line, insert below T line

                        var insertIndex = noOfTaxLine(grid.dataSource.data(), grid.dataSource.data()[idx + 1].SeqNo);

                        //grid.dataSource.insert(idx + 2, {});
                        grid.dataSource.insert(idx + insertIndex, {});
                        Tlinefound = true;
                    }
                    else
                    {
                        //not T line, insert saja
                        grid.dataSource.insert(idx + 1, {});
                    }
                }
                else
                {
                    //no next line, insert saja
                    grid.dataSource.insert(idx + 1, {});
                }

                //grid.editRow($("#gldetailGrid tr:eq(" + (sel_idx + 2) + ")"));
                //grid.select($("#gldetailGrid tr:eq(" + (sel_idx + 2) + ")"));
                //test access using grid, dont use #gldetailgrid as id conflict
                if (Tlinefound)
                {
                    grid.editRow(grid.tbody.find("tr").eq(sel_idx + 2));
                    grid.select(grid.tbody.find("tr").eq(sel_idx + 2));
                }
                else
                {
                    grid.editRow(grid.tbody.find("tr").eq(sel_idx + 1));
                    grid.select(grid.tbody.find("tr").eq(sel_idx + 1));
                }

                grid.dataSource.data()[grid.items().index(grid.select())].rowid = grid.select().data("uid");

                //some form dont know why setting the currcode. follow saja then
                if (currCode !== undefined && currCode !== null && currCode !== "")
                {
                    grid.dataSource.data()[grid.items().index(grid.select())].CurrCode = currCode;
                }

                if (currKey !== undefined && currKey !== null && currKey !== "")
                {
                    grid.dataSource.data()[grid.items().index(grid.select())].CurrKey = currKey;
                }
            }
            else
            {
                grid.addRow();
                grid.select(grid.tbody.find("tr").eq(grid.dataSource.data().length - 1));
                grid.dataSource._data[grid.dataSource.data().length - 1].rowid = grid.select().data("uid");

                if (currCode !== undefined && currCode !== null && currCode !== "")
                {
                    grid.dataSource._data[grid.dataSource.data().length - 1].CurrCode = currCode;
                }

                if (currKey !== undefined && currKey !== null && currKey !== "")
                {
                    grid.dataSource._data[grid.dataSource.data().length - 1].CurrKey = currKey;
                }
            }
        }
        catch (ex)
        {
            //fall back to add at the last row if error occurs
            grid.addRow();
            grid.select(grid.tbody.find("tr").eq(grid.dataSource.data().length - 1));
            grid.dataSource._data[grid.dataSource.data().length - 1].rowid = grid.select().data("uid");

            if (currCode !== undefined && currCode !== null && currCode !== "")
            {
                grid.dataSource._data[grid.dataSource.data().length - 1].CurrCode = currCode;
            }

            if (currKey !== undefined && currKey !== null && currKey !== "")
            {
                grid.dataSource._data[grid.dataSource.data().length - 1].CurrKey = currKey;
            }
        }

        //update seqNo skip Tax line first, then set Tax line's seqNo to be the same as the line above it (base line)
        let dslen = grid.dataSource.data().length;
        let seqNo = 1;

        let tempData = [];
        for (var ik = 0; ik < dslen; ik++)
        {
            if (grid.dataSource.data()[ik].Ind.startsWith("T"))
            {
                let tempBase = findBaseLine(grid.dataSource.data(), grid.dataSource.data()[ik].SeqNo);
                tempData.push({
                    TaxUid: grid.dataSource.data()[ik].uid,
                    BaseLineUid: tempBase
                });
            }
        }

        for (var ik = 0; ik < dslen; ik++)
        {
            //set general condition here to ease copy paste =D
            //if bank cash then V or O or T, others O or T
            if (source == "BP" || source == "BR" || source == "CP" || source == "CR")
            {
                if (grid.dataSource.data()[ik].Ind.startsWith("V") || grid.dataSource.data()[ik].Ind.startsWith("O"))
                {
                    grid.dataSource.data()[ik].SeqNo = seqNo;
                    seqNo = seqNo + 1;
                }
                else
                {
                    grid.dataSource.data()[ik].SeqNo = 0;
                }
            }
            else if (source == "PI")
            {
                if (grid.dataSource.data()[ik].Ind.startsWith("O") || grid.dataSource.data()[ik].Ind.startsWith("I") || grid.dataSource.data()[ik].Ind.startsWith("CW"))
                {
                    grid.dataSource.data()[ik].SeqNo = seqNo;
                    seqNo = seqNo + 1;
                }
                else
                {
                    grid.dataSource.data()[ik].SeqNo = 0;
                }
            }
            else
            {
                if (grid.dataSource.data()[ik].Ind.startsWith("O"))
                {
                    grid.dataSource.data()[ik].SeqNo = seqNo;
                    seqNo = seqNo + 1;
                }
                else
                {
                    grid.dataSource.data()[ik].SeqNo = 0;
                }
            }
        }

        for (var ik = 0; ik < dslen; ik++)
        {
            //set general condition here to ease copy paste =D
            //if bank cash then V or O or T, others O or T
            if (source == "BP" || source == "BR" || source == "CP" || source == "CR")
            {
                if (grid.dataSource.data()[ik].Ind.startsWith("T"))
                {
                    if (ik > 0)
                    {
                        /*
                        if (grid.dataSource.data()[ik - 1].Ind.startsWith("V") || grid.dataSource.data()[ik - 1].Ind.startsWith("O")) {
                            grid.dataSource.data()[ik].SeqNo = grid.dataSource.data()[ik - 1].SeqNo;
                        }*/
                        grid.dataSource.data()[ik].SeqNo = findBaseLineSeqNo(grid.dataSource.data()[ik].uid, tempData, grid.dataSource.data());
                    }
                }
            }
            else if (source == "PI")
            {
                if (grid.dataSource.data()[ik].Ind.startsWith("T"))
                {
                    if (ik > 0)
                    {
                        /*
                        if (grid.dataSource.data()[ik - 1].Ind.startsWith("O") || grid.dataSource.data()[ik - 1].Ind.startsWith("I")) {
                            grid.dataSource.data()[ik].SeqNo = grid.dataSource.data()[ik - 1].SeqNo;
                        }*/
                        grid.dataSource.data()[ik].SeqNo = findBaseLineSeqNo(grid.dataSource.data()[ik].uid, tempData, grid.dataSource.data());
                    }
                }
            }
            else
            {
                if (grid.dataSource.data()[ik].Ind.startsWith("T"))
                {
                    if (ik > 0)
                    {
                        grid.dataSource.data()[ik].SeqNo = findBaseLineSeqNo(grid.dataSource.data()[ik].uid, tempData, grid.dataSource.data());
                        /*if (grid.dataSource.data()[ik - 1].Ind.startsWith("O")) {
                            grid.dataSource.data()[ik].SeqNo = grid.dataSource.data()[ik - 1].SeqNo;
                        }*/
                    }
                }
            }
        }
    };

    let _calculateDueDate = function (gldate, dueDays, payTermType)
    {
        var date = new Date(gldate);

        if (payTermType == undefined || payTermType != "EOM")
        {
            if (dueDays != null)
            {
                date.setDate(date.getDate() + dueDays);
                return date;
            }
            else
            {
                return Date(2999, 11, 31);
            }
        }
        else
        {
            return new Date(date.getFullYear(), date.getMonth() + 1, 0);
        }
    };

    let _OUAllocFunction = function (form, headerData, detgrid, ouAllocDetailDataSource, ouAllocDataSource, statusObj, childScope)
    {
        //still in progress ~~~ 16/01/2019 KY
        let detData = detgrid.dataSource.data();
        var selectedList = [];

        let result = {
            isValid: true
        };

        angular.forEach(detData, function (selectedItem, index)
        {
            if (selectedItem.IsSelect)
            {
                selectedList.push(selectedItem);
            }
        });

        //344	1	1	No Record Selected	No record selected. In order to proceed please select one record.
        //343	1	1	Multiple Record Selected	Multiple record selected. In order to proceed please select only one record.

        if (selectedList.length == 0)
        {
            result.msgHeaderIcon = angular.copy($rootScope.MsgIcon.ERROR);
            result.msgHeader = (broadcastService.getMsg(344).MsgBody);
            result.isValid = false;
        }
        else if (selectedList.length > 1)
        {
            result.msgHeaderIcon = angular.copy($rootScope.MsgIcon.ERROR);
            result.msgHeader = (broadcastService.getMsg(343).MsgBody);
            result.isValid = false;
        }
        else if (selectedList.length == 1)
        {
            //headerData.Status != 'OP' || isApplied || isOUAllocation || IsOUAllocPosted

            let ouAllocChild = childScope.$new();

            //   $controller("assetAllocationController", { $scope: $scope.assetAllocationChild, assetAllocationParam: $scope.assetAllocationParam });

            $ocLazyLoad.load({
                name: "enterOUAlloc",
                files: ["finance/controllers/enterOUAllocController.js"]
            }).then(function ()
            {
                let modalInstance = $modal.open({
                    backdrop: 'static',
                    keyboard: false,
                    templateUrl: '/finance/views/enterOUAlloc.html',
                    controller: 'enterOUAllocController',
                    //scope: childScope,
                    scope: ouAllocChild,
                    resolve:
                    {
                        aValue: function ()
                        {
                            return {
                                ouAllocDetailDataSource: ouAllocDetailDataSource.customClone(),
                                ouAllocDataSource: ouAllocDataSource,
                                ouAllocFormAccNumAccDesc: selectedList[0].AccNumAccDesc,
                                ouAllocFormCCIDCodeCCIDDesc: selectedList[0].CCIDCodeCCIDDesc,
                                ouAllocFormOrigTransAmt: selectedList[0].OrigTransAmt,
                                ouAllocFormQty: selectedList[0].Qty,
                                Status: headerData.Status,
                                statusObj: statusObj,
                                selectedItem: selectedList[0],
                                detgrid: detgrid,
                            };
                        }
                    }
                });

                modalInstance.result.then(function (returnData)
                {
                });
            });
        }

        return result;
    };

    let _CCTypeDataSource = function ()
    {
        if (Array.isArray($rootScope.SubscribeAddOn) && $rootScope.SubscribeAddOn.includes("FARM"))
        {
            return {
                data: [
                    { CCTypeCode: "Bank", CCTypeDesc: "1.Bank Setup" },
                    { CCTypeCode: "Capex", CCTypeDesc: "3.Capex Setup" },
                    { CCTypeCode: "Cash", CCTypeDesc: "4.Cash Setup" },
                    { CCTypeCode: "Contact", CCTypeDesc: "5.Contact Setup" },
                    { CCTypeCode: "Department", CCTypeDesc: "16.Department Setup" },
                    //{ CCTypeCode: "Felling Block", CCTypeDesc: "12.Block Setup" },
                    { CCTypeCode: "Fixed Asset", CCTypeDesc: "15.Fixed Asset" },
                    { CCTypeCode: "InterOU Contact", CCTypeDesc: "14.InterOU Contact Setup" },
                    { CCTypeCode: "Mature Block", CCTypeDesc: "2.Mature Setup" },
                    { CCTypeCode: "Mill Equipment", CCTypeDesc: "13.Mill Equipment Setup" },
                    { CCTypeCode: "New Planting Block", CCTypeDesc: "10.Block Setup" },
                    { CCTypeCode: "Nursery Batch", CCTypeDesc: "9.Nursery Batch Setup" },
                    { CCTypeCode: "Replanting Block", CCTypeDesc: "11.Block Setup" },
                    { CCTypeCode: "Store", CCTypeDesc: "8.Store Setup" },
                    { CCTypeCode: "Tax", CCTypeDesc: "6.Tax Setup" },
                    { CCTypeCode: "Produce", CCTypeDesc: "17.Produce Setup" },
                    { CCTypeCode: "Vehicle", CCTypeDesc: "7.Vehicle Setup" }
                ]
            }
        }
        else
        {
            return {
                data: [
                    { CCTypeCode: "Bank", CCTypeDesc: "1.Bank Setup" },
                    { CCTypeCode: "Capex", CCTypeDesc: "3.Capex Setup" },
                    { CCTypeCode: "Cash", CCTypeDesc: "4.Cash Setup" },
                    { CCTypeCode: "Contact", CCTypeDesc: "5.Contact Setup" },
                    { CCTypeCode: "Department", CCTypeDesc: "16.Department Setup" },
                    //{ CCTypeCode: "Felling Block", CCTypeDesc: "12.Block Setup" },
                    { CCTypeCode: "Fixed Asset", CCTypeDesc: "15.Fixed Asset" },
                    { CCTypeCode: "InterOU Contact", CCTypeDesc: "14.InterOU Contact Setup" },
                    { CCTypeCode: "Mature Block", CCTypeDesc: "2.Mature Setup" },
                    { CCTypeCode: "Mill Equipment", CCTypeDesc: "13.Mill Equipment Setup" },
                    { CCTypeCode: "New Planting Block", CCTypeDesc: "10.Block Setup" },
                    { CCTypeCode: "Nursery Batch", CCTypeDesc: "9.Nursery Batch Setup" },
                    { CCTypeCode: "Replanting Block", CCTypeDesc: "11.Block Setup" },
                    { CCTypeCode: "Store", CCTypeDesc: "8.Store Setup" },
                    { CCTypeCode: "Tax", CCTypeDesc: "6.Tax Setup" },
                    //{ CCTypeCode: "Produce", CCTypeDesc: "17.Produce Setup" },
                    { CCTypeCode: "Vehicle", CCTypeDesc: "7.Vehicle Setup" }
                ]
            }
        }
    };

    let _contructInterOU = function (grp, gldetailDataSource)
    {
        //ou allocation is not deleted properly
        //so need to manually compare with gldetailsource to get list of inter ou
        let interOUList = [];
        for (var tt = 0; tt < gldetailDataSource._data.length; tt++)
        {
            var selectedS = gldetailDataSource._data[tt];
            var mika = grp.filter(function (item)
            {
                return ((selectedS.TransDetKey != 0 && item.TransDetKey == selectedS.TransDetKey) || (selectedS.rowid != "" && selectedS.rowid != null && item.rowid == selectedS.rowid))
            });

            if (mika.length > 0)
            {
                if (!interOUList.includes(mika[0].OUKey))
                {
                    interOUList.push(mika[0].OUKey);
                }
            }
        }
        return interOUList;
    };

    let _gotOUAllocData = function (headerData, detData, allocData, finSettingData)
    {
        let breakLoop = false;

        angular.forEach(detData, function (data, index)
        {
            if (!breakLoop)
            {
                if ((headerData.Source == "PI" || headerData.Source == "BP" || headerData.Source == "CP" || headerData.Source == "DN" || headerData.Source == "CN" || headerData.Source == "GJ"
                    || headerData.Source == "BR" || headerData.Source == "CR" || headerData.Source == "SI" || headerData.Source == "SBR" || headerData.Source == "SB") && finSettingData[0].EnableOUAllocChecking)
                {
                    var rawData = finSettingData[0].finGLConfgOUAllocDetails;
                    var copyOUAlloc = allocData;
                    rawData = rawData.filter(function (item)
                    {
                        if (item.AccKey == data.AccKey)
                        {
                            return true;
                        }
                        else
                        {
                            return false;
                        }
                    });

                    if (rawData.length > 0)
                    {
                        copyOUAlloc = copyOUAlloc.filter(function (item)
                        {
                            if ((data.TransDetKey != 0 && item.TransDetKey == data.TransDetKey) || (data.rowid != null && data.rowid != "" && item.rowid == data.rowid))
                            {
                                return true;
                            }
                            else
                            {
                                return false;
                            }
                        });
                        if (copyOUAlloc.length == 0)
                        {
                            breakLoop = true;
                        }
                    }
                }
            }
        });

        // if break loop means checking fails
        return breakLoop;
    };

    let _addNewLineOUAlloc = function (grid, TransDetKey, rowid, currCode, currKey)
    {
        try
        {
            let sel_idx = grid.select().index();
            let idx = grid.dataSource.indexOf(grid.dataItem(grid.select()));

            if (idx >= 0)
            {
                grid.dataSource.insert(idx + 1, {});

                grid.editRow(grid.tbody.find("tr").eq(sel_idx + 1));
                grid.select(grid.tbody.find("tr").eq(sel_idx + 1));

                grid.dataSource.data()[grid.items().index(grid.select())].rowid = grid.select().data("uid");

                //some form dont know why setting the currcode. follow saja then
                if (currCode !== undefined && currCode !== null && currCode !== "")
                {
                    grid.dataSource.data()[grid.items().index(grid.select())].CurrCode = currCode;
                }

                if (currKey !== undefined && currKey !== null && currKey !== "")
                {
                    grid.dataSource.data()[grid.items().index(grid.select())].CurrKey = currKey;
                }

                grid.dataSource.data()[grid.items().index(grid.select())].rowid = rowid;
                grid.dataSource.data()[grid.items().index(grid.select())].TransDetKey = TransDetKey;
            }
            else
            {
                grid.addRow();
                grid.select(grid.tbody.find("tr").eq(grid.dataSource.data().length - 1));
                grid.dataSource._data[grid.dataSource.data().length - 1].rowid = rowid;
                grid.dataSource._data[grid.dataSource.data().length - 1].TransDetKey = TransDetKey;

                if (currCode !== undefined && currCode !== null && currCode !== "")
                {
                    grid.dataSource._data[grid.dataSource.data().length - 1].CurrCode = currCode;
                }

                if (currKey !== undefined && currKey !== null && currKey !== "")
                {
                    grid.dataSource._data[grid.dataSource.data().length - 1].CurrKey = currKey;
                }
            }
        }
        catch (ex)
        {
            //fall back to add at the last row if error occurs
            grid.addRow();
            grid.select(grid.tbody.find("tr").eq(grid.dataSource.data().length - 1));
            grid.dataSource._data[grid.dataSource.data().length - 1].rowid = rowid;
            grid.dataSource._data[grid.dataSource.data().length - 1].TransDetKey = TransDetKey;

            if (currCode !== undefined && currCode !== null && currCode !== "")
            {
                grid.dataSource._data[grid.dataSource.data().length - 1].CurrCode = currCode;
            }

            if (currKey !== undefined && currKey !== null && currKey !== "")
            {
                grid.dataSource._data[grid.dataSource.data().length - 1].CurrKey = currKey;
            }
        }

        let dslen = grid.dataSource.data().length;
        let seqNo = 1;

        for (var ik = 0; ik < dslen; ik++)
        {
            grid.dataSource.data()[ik].SeqNo = seqNo;
            seqNo = seqNo + 1;
        }
    };

    let _allSource = function () {

        return [{ DocSourceDesc: "AJ - Closed-Period Adjustment", Source: "AJ" },
        { DocSourceDesc: "BP - Bank Payment", Source: "BP" },
        { DocSourceDesc: "BR - Bank Receipt", Source: "BR" },
        { DocSourceDesc: "CM - Cost To Maturity", Source: "CM" },
        { DocSourceDesc: "CN - Credit Note", Source: "CN" },
        { DocSourceDesc: "CP - Cash Payment", Source: "CP" },
        { DocSourceDesc: "CR - Cash Receipt", Source: "CR" },
        { DocSourceDesc: "CRP - Creditor Payment", Source: "CRP" },
        { DocSourceDesc: "CT - Transaction Clearing", Source: "CT" },
        { DocSourceDesc: "DM - Account Migration Journal", Source: "DM" },
        { DocSourceDesc: "DN - Debit Note", Source: "DN" },
        { DocSourceDesc: "DRP - Debtor Payment Receipt", Source: "DRP" },
        { DocSourceDesc: "GJ - General Journal", Source: "GJ" },
        { DocSourceDesc: "PI - Purchase Invoice", Source: "PI" },
        { DocSourceDesc: "SB - Self-Billed Invoice", Source: "SB" },
        { DocSourceDesc: "SBR - Self-Billed Invoice Receipt", Source: "SBR" },
        { DocSourceDesc: "SI - Sales Invoice", Source: "SI" },
        { DocSourceDesc: "SIC - Sales Invoice(Issue to Customer)", Source: "SIC" },
        { DocSourceDesc: "SIE - Sales Invoice(Issue to Employee)", Source: "SIE" },
        { DocSourceDesc: $rootScope.CtryVersion == 'M' ? "TD - GST Consolidation" : "TD - Tax Consolidation", Source: "TD" }];
    };

    let _allDocType = function () {

        return [
            { DocTypeDesc: "BV - Bank Voucher", DocType: "BV" },
            { DocTypeDesc: "BVR - Bank Receipt Voucher", DocType: "BVR" },
            { DocTypeDesc: "CM - Cost To Maturity", Source: "CM" },
            { DocTypeDesc: "CN - Credit Note", DocType: "CN" },
            { DocTypeDesc: "CT - Transaction Clearing", DocType: "CT" },
            { DocTypeDesc: "CV - Cash Voucher", DocType: "CV" },
            { DocTypeDesc: "CVR - Cash Receipt Voucher", DocType: "CVR" },
            { DocTypeDesc: "DN - Debit Note", DocType: "DN" },
            { DocTypeDesc: "JV - General Journal", DocType: "JV" },
            { DocTypeDesc: "PI - Purchase Invoice", DocType: "PI" },
            { DocTypeDesc: "SB - Self-Billed Invoice", DocType: "SB" },
            { DocTypeDesc: "SBR - Self-Billed Invoice Receipt", DocType: "SBR" },
            { DocTypeDesc: "SI - Sales Invoice", DocType: "SI" },
            { DocTypeDesc: "SIC - Sales Invoice(Issue to Customer)", DocType: "SIC" },
            { DocTypeDesc: "SIE - Sales Invoice(Issue to Employee)", DocType: "SIE" },
            { DocTypeDesc: "TD - Tax Document", DocType: "TD" }
        ];

    };

    let _allDraftSource = function () {

        return [
        { DocSourceDesc: "BP - Bank Payment", Source: "BP" },
        { DocSourceDesc: "CRP - Creditor Payment", Source: "CRP" },
        ];
    };

    let _compileAppliedDoc = function (val) {

        if (val == "" || val == undefined) {
            return "";
        }

        let allNum = val.split(",");
        let newString = "";

        for (var tmd = 0; tmd < allNum.length; tmd++) {
            let text = allNum[tmd];
            let Key = text.substring(0, text.indexOf("_"));
            let ouCode = text.substring(text.indexOf("_") + 1, text.indexOf("@"));
            let Source = text.substring(text.indexOf("@") + 1, text.indexOf("&"));
            let docNum = text.substring(text.indexOf("&") + 1, text.length);

            newString = newString + '<a style="font-weight: bold;text-decoration: underline;cursor: pointer;" ng-click="openTrans('
                + "\'ACC\'," +
                Key + ",\'" + Source + "\'" + ')" > ' + docNum + ' (' + ouCode + ') </a>'
                + " ,"

        }

        if (newString.length > 0) {
            newString = newString.substring(0, newString.length - 2);
        }

        return newString;
    }

    let _compileAppliedDoc_SDA = function (val) {

        if (val == "" || val == undefined) {
            return "";
        }

        let allNum = val.split(",");
        let newString = "";

        for (var tmd = 0; tmd < allNum.length; tmd++) {
            let text = allNum[tmd];
            let Key = text.substring(0, text.indexOf("_"));
            let ouCode = text.substring(text.indexOf("_") + 1, text.indexOf("@"));
            let Source = text.substring(text.indexOf("@") + 1, text.indexOf("&"));
            let docNum = text.substring(text.indexOf("&") + 1, text.length);

            newString = newString + '<a style="font-weight: bold;text-decoration: underline;cursor: pointer;" ng-click="openTrans('
                + "\'SDA\'," +
                Key + ",\'" + Source + "\'" + ')" > ' + docNum + ' (' + ouCode + ') </a>'
                + " ,"

        }

        if (newString.length > 0) {
            newString = newString.substring(0, newString.length - 2);
        }

        return newString;
    }

    let _compileAppliedDoc_QUOT = function (val) {

        if (val == "" || val == undefined) {
            return "";
        }

        let allNum = val.split(",");
        let newString = "";

        for (var tmd = 0; tmd < allNum.length; tmd++) {
            let text = allNum[tmd];
            let Key = text.substring(0, text.indexOf("_"));
            let ouCode = text.substring(text.indexOf("_") + 1, text.indexOf("@"));
            let Source = text.substring(text.indexOf("@") + 1, text.indexOf("&"));
            let docNum = text.substring(text.indexOf("&") + 1, text.length);

            newString = newString + '<a style="font-weight: bold;text-decoration: underline;cursor: pointer;" ng-click="openTrans('
                + "\'QUOT\'," +
                Key + ",\'" + Source + "\'" + ')" > ' + docNum + ' (' + ouCode + ') </a>'
                + " ,"

        }

        if (newString.length > 0) {
            newString = newString.substring(0, newString.length - 2);
        }

        return newString;
    }

    let _compileAppliedDoc_QRS = function (val) {

        if (val == "" || val == undefined) {
            return "";
        }

        let allNum = val.split(",");
        let newString = "";

        for (var tmd = 0; tmd < allNum.length; tmd++) {
            let text = allNum[tmd];
            let Key = text.substring(0, text.indexOf("_"));
            let ouCode = text.substring(text.indexOf("_") + 1, text.indexOf("@"));
            let Source = text.substring(text.indexOf("@") + 1, text.indexOf("&"));
            let docNum = text.substring(text.indexOf("&") + 1, text.length);

            newString = newString + '<a style="font-weight: bold;text-decoration: underline;cursor: pointer;" ng-click="openTrans('
                + "\'QRS\'," +
                Key + ",\'" + Source + "\'" + ')" > ' + docNum + ' (' + ouCode + ') </a>'
                + " ,"

        }

        if (newString.length > 0) {
            newString = newString.substring(0, newString.length - 2);
        }

        return newString;
    }

    let _compileAppliedDoc_PO = function (val) {

        if (val == "" || val == undefined) {
            return "";
        }

        let allNum = val.split(",");
        let newString = "";

        for (var tmd = 0; tmd < allNum.length; tmd++) {
            let text = allNum[tmd];
            let Key = text.substring(0, text.indexOf("_"));
            let ouCode = text.substring(text.indexOf("_") + 1, text.indexOf("@"));
            let Source = text.substring(text.indexOf("@") + 1, text.indexOf("&"));
            let docNum = text.substring(text.indexOf("&") + 1, text.length);

            newString = newString + '<a style="font-weight: bold;text-decoration: underline;cursor: pointer;" ng-click="openTrans('
                + "\'PO\'," +
                Key + ",\'" + Source + "\'" + ')" > ' + docNum + ' (' + ouCode + ') </a>'
                + " ,"

        }

        if (newString.length > 0) {
            newString = newString.substring(0, newString.length - 2);
        }

        return newString;
    }

    let _ouAllocValidation = function (selectedList, headerData, finConfigDataSource) {
        let errMsg = "";
        var noAcc = false;
        var wrongAcc = false;
        let langKey = $rootScope.userSetting.langKey;

        if (selectedList.length == 1) {
            if (selectedList[0].AccKey <= 0) {
                noAcc = true;
            }
            else {
                var data = finConfigDataSource.getItemsbyCompKey(headerData.CompKey);
                if (data[0].finGLConfgOUAllocDetails == undefined) {
                    wrongAcc = true;
                }
                else {
                    if (data[0].finGLConfgOUAllocDetails.length == 0) {
                        wrongAcc = true;
                    }
                    else {
                        var rawData = data[0].finGLConfgOUAllocDetails;

                        rawData = rawData.filter(function (item) {
                            if (item.AccKey == selectedList[0].AccKey) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        });

                        if (rawData.length == 0) {
                            wrongAcc = true;
                        }
                    }
                }
            }

        }

        if (selectedList.length == 0) {
      
            errMsg = (broadcastService.getMsg(344).MsgBody);
        }
        else if (selectedList.length > 1) {
            errMsg = (broadcastService.getMsg(343).MsgBody);
        }
        else if (selectedList[0].Ind.startsWith("T")) {
            errMsg = $rootScope.label['5554'][langKey].LabelDesc;
        }
        else if (selectedList.length == 1 && wrongAcc == true) {  
            errMsg = (broadcastService.getMsg(598).MsgBody);
        }
        else if (selectedList.length == 1 && noAcc == true) {
            errMsg = $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [$rootScope.label['42'][langKey].LabelDesc]);
        }
        else if (selectedList.length == 1 && noAcc == false && wrongAcc == false && selectedList[0].IsRequiredCCID && selectedList[0].CCIDKey <= 0) {            
            errMsg = $filter('stringFormat')($rootScope.msg['1'][langKey].MsgBody, [$rootScope.label['24'][langKey].LabelDesc]);
        }

        return errMsg;
    }

    finServiceFactory.currentRecordValidation = _currentRecordValidation;

    finServiceFactory.checkFutureDate = _checkFutureDate;
    finServiceFactory.checkDirtyflag = _checkDirtyflag;
    finServiceFactory.updateCurrInfo = _updateCurrInfo;
    finServiceFactory.withdrawGL = _withdrawGL;
    finServiceFactory.retractGL = _retractGL;
    finServiceFactory.submitGL = _submitGL;
    finServiceFactory.serverRecordValidation = _serverRecordValidation;

    finServiceFactory.fieldValidation = _fieldValidation;
    finServiceFactory.fieldValidationGJ = _fieldValidationGJ;
    finServiceFactory.fieldValidationBC = _fieldValidationBC;
    finServiceFactory.fieldValidationDC = _fieldValidationDC;
    finServiceFactory.fieldValidationSkipDet = _fieldValidationSkipDet;
    finServiceFactory.fieldValidationBCSkipDet = _fieldValidationBCSkipDet;

    finServiceFactory.gldetailDataSource = _gldetailDataSource;
    finServiceFactory.glAccountingDataSource = _glAccountingDataSource;
    finServiceFactory.ouAllocDetailDataSource = _ouAllocDetailDataSource;
    finServiceFactory.ouAllocDataSource = _ouAllocDataSource;

    finServiceFactory.updateVendorInfo = _updateVendorInfo;
    finServiceFactory.updateBankInfo = _updateBankInfo;
    finServiceFactory.updateCashInfo = _updateCashInfo;

    finServiceFactory.TaxFunction = _TaxFunction;
    finServiceFactory.updateTaxFunc = _updateTaxFunc;
    finServiceFactory.OUAllocFunction = _OUAllocFunction;

    finServiceFactory.addNewLine = _addNewLine;
    finServiceFactory.calculateDueDate = _calculateDueDate;

    finServiceFactory.CCTypeDataSource = _CCTypeDataSource;
    finServiceFactory.contructInterOU = _contructInterOU;
    finServiceFactory.gotOUAllocData = _gotOUAllocData;
    finServiceFactory.addNewLineOUAlloc = _addNewLineOUAlloc;
    finServiceFactory.allDraftSource = _allDraftSource;
    finServiceFactory.allSource = _allSource;
    finServiceFactory.allDocType = _allDocType;
    finServiceFactory.compileAppliedDoc = _compileAppliedDoc;
    finServiceFactory.compileAppliedDoc_SDA = _compileAppliedDoc_SDA;
    finServiceFactory.compileAppliedDoc_QUOT = _compileAppliedDoc_QUOT;
    finServiceFactory.compileAppliedDoc_QRS = _compileAppliedDoc_QRS;
    finServiceFactory.compileAppliedDoc_PO = _compileAppliedDoc_PO;
    finServiceFactory.ouAllocValidation = _ouAllocValidation;

    return finServiceFactory;
}]);